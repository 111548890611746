@import "../../sma-shared/Styles/index.scss";

.notfound-container {
  background-color: #f5f7fa;
  display: flex;
  height: 100%;
  .left-container {
    display: flex;
    flex: 1 0 70%;
    flex-direction: column;
    text-align: left;
    .content {
      max-width: 800px;
      margin-left: 190px;
      margin-top: 90px;
      h2 {
        margin-bottom: 30px;
        font-size: 40px;
        font-weight: 400;
        max-width: 350px;
        font-family: HaufeMerriweatherSansLt;
      }
      p {
        font-family: HaufeMerriweatherSansLt;
        font-size: 12px;
        line-height: 22px;
        letter-spacing: 0;
        margin-bottom: 30px;
      }
      .btn-active {
        height: 41px; /* IE11 Fix */
        color: $color-white;
        background-color: $color-blue-ribbon-1;
        width: fit-content;
        padding: 10px 26px;
        border-radius: 5px;
        transition: all 0.3s ease;
        &:hover {
          background-color: lighten($color-blue-ribbon-1, 10%);
        }
      }
    }
  }
  .right-container {
    height: 100%;
    flex: 1 0 30%;
    background-image: url("../../Assets/png/img-one-way.jpg");
    background-size: cover;
    background-position: center;
  }
}
