@import "../../sma-shared/Styles/index.scss";

.no-cases {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background: $color-gray;
  text-align: center;

  background: linear-gradient(
      to right,
      $color-gray 50%,
      rgba(255, 255, 255, 0) 0%
    ),
    linear-gradient($color-gray 50%, rgba(255, 255, 255, 0) 0%),
    linear-gradient(to right, $color-gray 50%, rgba(255, 255, 255, 0) 0%),
    linear-gradient($color-gray 50%, rgba(255, 255, 255, 0) 0%);
  background-position: top, right, bottom, left;
  background-repeat: repeat-x, repeat-y;
  background-size: 10px 1px, 1px 10px;
  padding: 5em;
  h5 {
    font-size: 14px;
    color: $color-tuna;
  }
}
