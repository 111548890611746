@import "../../sma-shared/Styles/index.scss";

.text-input-container {
  text-align: left;
  .question-input-label {
    width: 100%; /* IE11 FIX */
  }
  .unit-input {
    background-color: #fff;
    display: block;
    width: 100%;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    font-family: $font-name-merriweather-sans;
    height: 52px;
    border-radius: 4px;
    padding: 14px 20px;
    text-align: left;
    overflow: hidden;
    white-space: nowrap;
  }
  button {
    &:hover {
      border-color: $color-royal-blue;
    }
    svg {
      transform: rotate(90deg);
    }
  }
}
