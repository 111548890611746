@import "../../sma-shared/Styles/index.scss";

.file-row {
  display: flex;
  border-top: 1px solid $color-hawkes-blue;
  padding: 10px 0px 9px 0px;
  span {
    height: 14px;
    width: 14px;
  }

  .filesize {
    color: #8f9299;
    margin-left: auto;
    width: auto;
  }

  .delete-button {
    align-self: center;
    height: 14px;
    width: 14px;
    margin-right: 0px;
    margin-top: -5px;
    padding: 0px;
    svg {
      path {
        fill: $color-royal-blue;
      }
    }
  }
}
