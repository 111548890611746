// mixin used to add the posibility of fluid increase/decrease of a property value from one min viewport size to a max viewport size
// we do this to avoid jumping from one size to the next at media queries
// we also avoid the property value from getting smaller than our minimum or bigger than our maximum
@mixin fluid-type(
  $min-vw,
  $max-vw,
  $min-property-size,
  $max-property-size,
  $property: font-size
) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-property-size);
  $u4: unit($max-property-size);
  // if the sizes that are refering to min and max propery values are rem, convert them in px
  @if $u3 == $u4 and $u3 == "rem" {
    $min-property-size: strip-unit($min-property-size) * $font-size-base-rem;
    $max-property-size: strip-unit($max-property-size) * $font-size-base-rem;
    $u3: unit($min-property-size);
    $u4: unit($max-property-size);
  }
  // we check if all units used in the calculation are the same
  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
    & {
      #{$property}: $min-property-size;
      @media screen and (min-width: $min-vw) {
        // calculation to set the property value to the concerning value between min and max for the current viewport size // [minimum size] + ([maximum size] - [minimum size]) * ((100vw - [minimum viewport width]) / ([maximum viewport width] - [minimum viewport width]));
        #{$property}: calc(
          #{$min-property-size} + #{strip-unit(
              $max-property-size - $min-property-size
            )} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)})
        );
      }
      @media screen and (min-width: $max-vw) {
        #{$property}: $max-property-size;
      }
    }
  }
}

@mixin fluid-type-height(
  $min-vh,
  $max-vh,
  $min-property-size,
  $max-property-size,
  $property: font-size
) {
  $u1: unit($min-vh);
  $u2: unit($max-vh);
  $u3: unit($min-property-size);
  $u4: unit($max-property-size);
  // if the sizes that are refering to min and max propery values are rem, convert them in px
  @if $u3 == $u4 and $u3 == "rem" {
    $min-property-size: strip-unit($min-property-size) * $font-size-base-rem;
    $max-property-size: strip-unit($max-property-size) * $font-size-base-rem;
    $u3: unit($min-property-size);
    $u4: unit($max-property-size);
  } // we check if all units used in the calculation are the same
  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
    & {
      #{$property}: $min-property-size;
      @media screen and (min-height: $min-vh) {
        // calculation to set the property value to the concerning value between min and max for the current viewport size // [minimum size] + ([maximum size] - [minimum size]) * ((100vw - [minimum viewport width]) / ([maximum viewport width] - [minimum viewport width]));
        #{$property}: calc(
          #{$min-property-size} + #{strip-unit(
              $max-property-size - $min-property-size
            )} * ((100vh - #{$min-vh}) / #{strip-unit($max-vh - $min-vh)})
        );
      }
      @media screen and (min-height: $max-vh) {
        #{$property}: $max-property-size;
      }
    }
  }
}
@mixin fluid-type-header-height(
  $min-vw,
  $max-vw,
  $min-property-size,
  $max-property-size,
  $property: min-height
) {
  & {
    #{$property}: calc(100vh - #{$min-property-size});
    @media screen and (min-width: $min-vw) {
      // calculation to set the property value to the concerning value between min and max for the current viewport size
      // [minimum size] + ([maximum size] - [minimum size]) * ((100vw - [minimum viewport width]) / ([maximum viewport width] - [minimum viewport width]));
      #{$property}: calc(
        100vh - #{fluid-value-calculator(
            $min-vw,
            $max-vw,
            $min-property-size,
            $max-property-size
          )}
      );
    }
    @media screen and (min-width: $max-vw) {
      #{$property}: calc(100vh - #{$max-property-size});
    }
  }
}
// set stroke value for svg paths which have stroke and it's not set on none
@mixin color_svg_stroke($color: $color-oxford-blue) {
  [stroke] {
    &:not([stroke="none"]) {
      stroke: $color;
    }
  }
}
// set fill value for svg paths which have fill and it's not set on none
@mixin color_svg_fill($color: $color-oxford-blue) {
  [fill] {
    &:not([fill="none"]) {
      fill: $color;
    }
  }
}
// set stroke and fill values using the mixins declared above
@mixin color_svg($color: $color-oxford-blue) {
  @include color_svg_stroke($color);
  @include color_svg_fill($color);
}
@mixin no_select_with_pointer() {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}
@mixin change_color_placeholder {
  &::-webkit-input-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
  &::-ms-input-placeholder {
    @content;
  }
}
@mixin no_select() {
  @include no_select_with_pointer();
  pointer-events: none !important;
}
@mixin no_shadow() {
  box-shadow: none;
  outline: 0;
  -webkit-box-shadow: none;
}
// When using ::before and ::after you'll always need these three, so we're saving two lines of code every time you use this
@mixin pseudo($display: block, $pos: absolute, $content: "") {
  content: $content;
  display: $display;
  position: $pos;
}
// mixin to create striped gradient
@mixin striped() {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3c?xml version='1.0' encoding='UTF-8'?%3e%3csvg width='19px' height='19px' viewBox='0 0 19 19' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3e%3cg id='line-pattern' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' stroke-linecap='square'%3e%3cpath d='M-0.5,19.5 L19.5,-0.5' id='Line' stroke='%23E1E1E1' stroke-width='2'%3e%3c/path%3e%3cpath d='M-10.5,10.5 L9.5,-9.5' id='Line' stroke='%23E1E1E1' stroke-width='2'%3e%3c/path%3e%3cpath d='M0.5,37.5 L20.5,17.5' id='Line' stroke='%23E1E1E1' stroke-width='2'%3e%3c/path%3e%3c/g%3e%3c/svg%3e");
  background-repeat: repeat;
  background-attachment: fixed;
}
// mixin to style solid btn
@mixin btn-solid(
  $button-color-base: $color-blue-ribbon-1,
  $color-text: $color-white
) {
  background: $button-color-base;
  &,
  &:focus,
  &:hover,
  &:visited,
  &:active {
    // box-shadow: 0 14px 20px 0 rgba(0, 0, 0, 0.15);
    color: $color-text !important;
  }
}
// mixin to style btn transparent
@mixin btn-transparent(
  $button-color-base: $color-blue-ribbon-1,
  $button-color-hover: $color-white,
  $button-border-color: $color-blue-ribbon-1,
  $button-background-color: $color-blue-ribbon-1,
  $border-base: 2px solid
) {
  background: transparent;
  border: $border-base;
  border-color: $button-border-color;
  &,
  &:active,
  &:visited {
    color: $button-color-base;
  }
  .no-touch & {
    &:focus,
    &:hover {
      background-color: $button-background-color;
      color: $button-color-hover;
    }
  }
}
@mixin btn-toggle-arrow($height: 12px) {
  &:after {
    height: $height;
    transform: translate(-75%, -50%) rotate(45deg);
  }
  &:before {
    height: $height;
    transform: translate(75%, -50%) rotate(-45deg);
  }
}
@mixin font-smoothing {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@mixin link(
  $color: $color-cerulean-2,
  $color-hover: $color-cerulean-2,
  $text-decoration: none,
  $text-decoration-hover: none
) {
  &,
  &:active,
  &:visited {
    color: $color;
    text-decoration: $text-decoration;
  }
  .no-touch & {
    &:hover,
    &:focus {
      color: $color-hover;
      text-decoration: $text-decoration-hover;
    }
  }
  &:hover,
  &:focus {
    color: $color-hover;
    text-decoration: $text-decoration-hover;
  }
}
@mixin link-color($color: $color-cerulean-2, $color-hover: $color-cerulean-2) {
  &,
  &:active,
  &:visited {
    color: $color;
  }
  .no-touch & {
    &:hover,
    &:focus {
      color: $color-hover;
    }
  }
}
@mixin filter_separator($color: $color-iron, $margin: $base-spacing-2x) {
  border: 1px solid $color;
  height: 0.08%;
  margin: $margin 0;
}
@mixin checkbox-filter(
  $color-background: $color-white,
  $color-border: $color-black,
  $color-check: $color-blue-ribbon-1,
  $padding: $base-spacing-2x
) {
  & {
    display: none;
    margin-right: $base-spacing-2x;
    & + label {
      border: 1px solid $color-border;
      background-color: $color-background;
      display: inline-block;
      margin-right: $base-spacing-2x;
      margin-left: $base-spacing-2x;
      padding: $padding;
      position: relative;
    }
    &:checked + label {
      border-color: $color-check;
      &:after {
        content: "\2714";
        color: $color-check;
        font-size: $font-size-regular-checkbox;
        left: 2px;
        position: absolute;
        top: -5px;
      }
    }
  }
}
@mixin checkbox-radio(
  $color-label-background: $color-mischka-4,
  $color-checked: $color-blue-ribbon-1,
  $color-checked-label: $color-blue-ribbon-1,
  $color-disabled: $color-manatee,
  $color-bullet: $color-white,
  $size: 20px,
  $margin-right: $base-spacing-4x,
  $margin-bottom: 0px,
  $border-width: 2px
) {
  & {
    max-width: 100px;
    opacity: 0;
    position: absolute;
    + .radio-label {
      cursor: pointer;
      text-transform: none;
      font-weight: normal;
      &:empty {
        &:before {
          margin-right: 0;
        }
      }
      &:before {
        background: $color-label-background;
        border-radius: 100%;
        border: $border-width solid $color-iron;
        content: "";
        display: inline-block;
        font-family: $font-family-merriweather-sans-light;
        font-size: $font-size-input-radio;
        height: $size;
        letter-spacing: 0.18px;
        line-height: $line-height-input-radio;
        margin-right: $base-spacing-3x;
        margin-bottom: $margin-bottom;
        position: relative;
        text-align: center;
        vertical-align: middle;
        width: $size;
        min-width: $size;
      }
    }
    &:checked {
      + .radio-label {
        color: $color-checked-label;
        &:before {
          background-color: $color-checked;
          box-shadow: inset 0 0 0 4px $color-bullet;
          border-color: $color-checked;
        }
      }
    }
    &:focus {
      + .radio-label {
        &:before {
          border-color: $color-checked;
          outline: none;
        }
      }
    }
    &:disabled {
      + .radio-label {
        &:before {
          background-color: $color-label-background;
          border: 2px solid $color-iron;
          box-sizing: border-box;
          color: $color-disabled;
        }
      }
    }
  }
}
@mixin checkbox-radio-locked($color-locked: $color-iron) {
  & {
    + .radio-label {
      &:before {
        border-color: $color-locked;
      }
    }
    &:checked {
      + .radio-label {
        color: $color-locked;
        &:before {
          background-color: $color-locked;
          border-color: $color-locked;
        }
      }
    }
    &:focus {
      + .radio-label {
        &:before {
          border-color: $color-locked;
        }
      }
    }
    &:disabled {
      + .radio-label {
        &:before {
          border: 2px solid $color-locked;
          color: $color-locked;
        }
      }
    }
  }
}
@mixin search-input() {
  & {
    &:focus {
      outline: none;
    }
    border: none;
    min-height: 28px;
    padding: $base-spacing $base-spacing $base-spacing-2x $base-spacing;
  }
}
// When using ::before and ::after you'll always need these three, so we're saving two lines of code every time you use this
@mixin pseudo($display: block, $pos: absolute, $content: "") {
  content: $content;
  display: $display;
  position: $pos;
}
@mixin box-decoration($color: $color-mischka-3, $height: 380px, $width: 380px) {
  bottom: 0px;
  background: $color;
  height: $height;
  position: absolute;
  right: 0;
  width: $width;
  z-index: 0;
}
@mixin workflow($color: $color-mischka-4) {
  background-color: $color;
  color: black;
  padding-right: 0;
  padding-left: 0;
}
@mixin circle(
  $width: 30px,
  $height: 30px,
  $color: $color-mischka-4,
  $color-border: $color-oxford-blue
) {
  width: $width;
  height: $height;
  background: $color;
  border: 2px solid $color-border;
  -webkit-border-radius: calc($width / 2);
  -moz-border-radius: calc($width / 2);
  border-radius: calc($width / 2);
}
@mixin draw-line($color, $height, $width) {
  background: $color;
  height: $height;
  width: $width;
}
@mixin question-answer() {
  color: $color-blue-ribbon-1;
  cursor: pointer;
  display: flex;
  justify-content: center;
  min-width: 57px;
  margin: -1px; /* fix outline issues when zooming */
  padding: $base-spacing-2x $base-spacing-4x;
}
@mixin checkbox-doughnut-radio(
  $color-label-background: #ffffff,
  $color-checked: $color-blue-ribbon-2,
  $color-checked-label: $color-blue-ribbon-2,
  $color-disabled: $color-manatee,
  $color-bullet: $color-white,
  $size: 16px,
  $margin-right: $base-spacing-4x,
  $margin-bottom: 0px,
  $border-width: 2px
) {
  & {
    max-width: 100px;
    opacity: 0;
    position: absolute;
    line-height: 24px;
    + .radio-label {
      cursor: pointer;
      text-transform: none;
      font-weight: normal;
      letter-spacing: 0;
      &:empty {
        &:before {
          margin-right: 0;
        }
      }
      &:before {
        border: 1px solid $color-manatee;
        border-radius: $size;
        content: "";
        cursor: pointer;
        display: inline-block;
        font-family: $font-family-merriweather-sans-light;
        font-size: $font-size-input-radio;
        height: $size;
        letter-spacing: 0.18px;
        line-height: 24px;
        margin-right: $base-spacing-3x;
        margin-bottom: $margin-bottom;
        position: relative;
        text-align: center;
        vertical-align: middle;
        width: $size;
      }
    }
    &:checked {
      + .radio-label {
        color: $color-checked;
        &:before {
          background-color: $color-label-background;
          border: 3px solid $color-checked;
        }
      }
    }
    &:focus {
      + .radio-label {
        &:before {
          border-color: $color-checked;
          outline: none;
        }
      }
    }
    &:disabled {
      + .radio-label {
        &:before {
          background-color: $color-label-background;
          box-sizing: border-box;
        }
      }
    }
  }
}
