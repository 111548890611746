@import "../../sma-shared/Styles/index.scss";
.radioButton {
  text-align: left;
  input[type="radio"] {
    width: 10px !important;
    @include checkbox-doughnut-radio();
    &[disabled] + .radio-label {
      pointer-events: none;
      cursor: default;
      opacity: 0.6;
    }
  }
  label {
    color: $color-tuna;
    font-family: $font-name-merriweather-sans-light;
    font-size: 14px;
    border: none;
  }
}

.radioButton.invalid {
  border: none;
  .radio-label {
    &:before {
      border: 2px solid $color-invalid !important;
    }
  }
}

.radio-error-message {
  color: $color-invalid;
  font-size: 12px;
  height: 100%;
  position: relative;
  width: 520px;
  text-align: left;
}
