$font-name-babas: "BebasNeue";

/* Haufe Fonts */
$font-name-merriweather: "HaufeMerriweather";
$font-name-merriweather-light: "HaufeMerriweatherLt";
$font-name-merriweather-xlight: "HaufeMerriweatherXLt";

$font-name-merriweather-sans: "HaufeMerriweatherSans";
$font-name-merriweather-sans-light: "HaufeMerriweatherSansLt";
$font-name-merriweather-sans-xlight: "HaufeMerriweatherSansXLt";

/* Roboto fonts */
$roboto: "robotoregular";
$roboto-italic: "robotoitalic";
$roboto-medium: "robotomedium";
$roboto-medium-italic: "robotomediumitalic";
