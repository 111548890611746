@import "../../Styles/index.scss";
.header {
  background-color: $color-white;
  z-index: 150;
  .header__content {
    background-color: $color-white;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    display: flex;
    font-family: $font-family-merriweather-sans;
    position: relative;
    z-index: 1;
    padding: 0px 20px;
    align-items: center;
    .header__links-container .header__page-link {
      padding-bottom: 19px;
    }
  }
  .header-logo {
    width: 200px;
    height: 100%;
  }
}
