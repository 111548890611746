@import "../../sma-shared/Styles/index.scss";

.help-window-container {
  background-color: #fff;
  position: absolute;
  top: 10%;
  left: 15%;
  height: 80%;
  width: 35%;
  z-index: 1005;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;

  .help-window__title-bar {
    background-color: $color-manatee;
    padding: 15px 20px;

    .title-bar__container {
      display: flex;

      .help-window__title {
        text-align: left;
        color: $color-white;
        font-size: 16px;
      }

      .help-window__close-button {
        justify-content: flex-end;
        margin-left: auto;
      }
    }
  }

  .help-window__content-container {
    padding: 15px 20px;
    text-align: left;
    overflow: auto;
  }

  .close-icon {
    width: 15px;
  }
}

.help-window-container.hidden {
  display: none;
}
