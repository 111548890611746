@import "../../sma-shared/Styles/index.scss";

// zurück btn
.back-button {
  height: 36px;
  font-family: HaufeMerriweatherSans;
  font-size: 14px;
  line-height: 16px;
  padding: 10px 20px 10px 20px;
  border-radius: 6px;
  background-color: $color-hawkes-blue;
  color: $color-tuna;
  margin-left: 0px;
  margin-right: auto;
  order: 1;
  width: max-content;
}
