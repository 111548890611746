@import "../../../Styles/index.scss";

.footer-links-left {
  a,
  button {
    @include link($color-white, $color-white, none, underline);
  }
  button {
    padding: 0;
  }
  @include no_select_with_pointer();
  align-items: flex-start;
  margin-right: auto;
}
