@import "../../../Styles/index.scss";
button {
  border: none;
  background: none;
}

@media screen and (max-width: 1100px) {
  .profile-container {
    margin: 0 15px; /* fix for small viewport scrollbar issue */
  }
}

@media screen and (min-width: 1100px) {
  .profile-container {
    margin: 0 36px;
  }
}

.profile-container {
  cursor: pointer;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;

  .full-name-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    font-family: $font-family-merriweather-sans;
    font-size: $font-size-full-name-container;
    .user-name {
      font-weight: $font-weight-bold;
      color: $color-green-blue;
    }
    .user-title {
      color: $color-manatee;
    }
  }
}

.social-icon-container {
  align-items: center;
  display: flex;
  margin-left: 15px;
  position: relative;
  width: 50px;
}

.social-icon-button {
  &:focus {
    outline: none;
  }
  &.social-icon-blue-border {
    border: 4px solid $color-blue-ribbon-2;
  }
  align-items: center;
  box-sizing: border-box;
  background-color: $color-green-blue;
  border: 4px transparent;
  border-radius: 50%;
  display: flex;
  height: 46px;
  justify-content: center;
  outline: none;
  padding: 0;
  width: 46px;
}

.user-initials-container {
  color: $color-white;
  font-family: $font-family-merriweather-sans;
  font-size: $font-size-user-initials-container;
  outline: none;
  height: 20px;
  letter-spacing: 0.46px;
  line-height: $line-height-user-initials-container;
  text-align: center;
  width: 42px;
}
