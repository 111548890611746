@import "../../../sma-shared/Styles/helpers/sma_colors";

.page-with-sidebar {
  display: flex;
  justify-content: space-between;
  height: -webkit-fill-available;
  height: 100%;

  .side-bar-right {
    background-color: #ffffff;
    width: 410px;
    padding-left: 41px;
    padding-right: 40px;
    padding-top: 48px;
    margin-top: -48px;
    margin-bottom: -8px;
    margin-right: 0px;

    .title {
      font-size: 20px;
      line-height: 40px;
      color: $color-black-pearl;
      margin-right: 30px;
      font-family: "HaufeMerriweatherSansLt";
      text-align: left;
      margin-bottom: 11px;
    }

    .description {
      color: #45484d;
      font-family: "HaufeMerriweatherSansLt";
      font-size: 14px;
      margin-bottom: 33px;
      text-align: left;
    }

    .collapsible-card {
      width: 100%;

      .collapsible-card:first-child {
        margin-top: -19px;
      }

      .collapsible-header {
        margin-top: 19px;
      }

      .collapsible-children {
        max-width: 100%;
        border-bottom: none;
      }
    }
    #receiver-sidebar {
      color: $color-royal-blue;
    }
  }
}

.icon-sidebar {
  cursor: pointer !important;
  display: initial !important;
  svg {
    height: 16px !important;
    width: 16px !important;
    margin-right: 5px !important;
    vertical-align: middle !important;
  }
}

.list-receiver-table {
  margin-bottom: 40px;

  tbody {
    tr {
      height: 40px;
      td:nth-child(1),
      td:nth-child(2),
      td:nth-child(3) {
        color: $color-royal-blue;
      }
    }
  }
  #optional-header {
    font-style: italic;
  }
}
