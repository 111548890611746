@import "../../sma-shared/Styles/index.scss";

.date-input-container {
  display: flex;
  position: relative;
  height: 100%;
}

.date-input-container.invalid {
  border: none;
}

.date-input-container.invalid input {
  border-color: $color-invalid;
}

.datepicker-input-container input:focus {
  outline: none;
}

.datepicker-input-container input {
  padding-right: 45px; /* not to overlap the calendar icon */
}

.datepicker-input-container {
  width: 100%;
}

.datepicker-icon {
  width: 100%;
  height: 100%;
  .react-datepicker-popper {
    will-change: unset !important;
    z-index: 4; /* should overlap the navigation bar */
  }
}

.datepicker-icon > div {
  height: 100%;
}

.datepicker-icon-wrapper {
  align-items: center;
  display: flex;
  height: 52px;
  width: 50px;
  justify-content: center;
  position: absolute;
  right: 0;
}

.datepicker-component-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

button#dateButton:focus {
  outline: 0;
}

#dateContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

#dateContainer:focus {
  outline: 0;
}

.calendar-icon-image {
  max-width: 14px;
  height: 14px;
}

.react-datepicker-wrapper {
  align-items: flex-end;
  display: flex !important;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.react-datepicker__input-container {
  height: 100%;
  width: 100%;
}

.react-datepicker__year-read-view {
  min-width: 63px; /* fix for IE11 */
}

.text-module-text .react-datepicker__input-container {
  border: none;
  width: 100%;
}

.text-module-text .react-datepicker-wrapper {
  display: inline-block !important;
  width: auto;
}

.datepicker-error-message {
  color: $color-invalid;
  font-size: 12px;
  height: 100%;
  position: relative;
  padding-top: 8px;
  width: 100%; /* works best for Falldaten error message */
  text-align: left;
}

.datepicker-icon-container {
  width: 100%;
  height: 100%;
  position: absolute;
  border-left: none;
  display: flex;
  align-items: center;
}
