@import "../../../sma-shared/Styles/index.scss";
@import "../../../sma-shared/Styles/helpers/sma_fonts";

.entry-check-container {
  label {
    font-size: 14px;
    color: $color-tuna;
    margin-bottom: 0.5em;
    font-family: $font-family-merriweather-sans-light;
    display: block;
  }
  .card-notification {
    background-color: rgba($color-notificaiton, 0.7);
    margin: 10px -40px -40px -30px;
    padding: 30px 30px 30px 40px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    div {
      text-align: left;
      font-size: 14px;
      margin-bottom: 1em;
      width: 100%; /* IE11 Fix */
      &:last-child {
        margin-bottom: 0;
      }
      p {
        font-size: 14px;
        font-family: $font-family-merriweather-sans-light;
      }
      a {
        color: $color-black-pearl;
        display: block;
      }

      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        /* IE11 FIX ONLY */
        p {
          height: 40px; /* IE11 Fix */
        }
        a {
          height: 40px; /* IE11 Fix */
        }
      }
    }
    svg {
      width: 14px;
      height: auto;
    }
    .link-text {
      word-wrap: break-word;
      margin-left: 11px;
      font-weight: bold;
      font-family: $font-family-merriweather-sans-light;
    }
  }

  .recommendations {
    text-align: left;
    color: $color-tuna;
  }
  .checkbox-container {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    label.checkbox {
      margin-top: 5px;
    }
  }

  .compliance-file-list {
    .summary-attachments-container {
      button {
        margin-left: 0px;
        color: black !important;
        font-weight: bold;
      }
      .icon-general.download-icon {
        margin-left: 0px !important;
        margin-top: -1px !important;
        width: 14px;
        height: 14px;
      }
      .summary-file-row {
        display: flex;
        justify-content: flex-end;
        flex-direction: row-reverse;
        color: black;
        font-family: $font-family-merriweather-sans-light;
      }
    }
  }
  .compliance-file-list.no-url-above {
    margin-top: 40px;
  }
}
