.application-content.row {
  // width: 1440px;
  margin: 0;
}

.page-content {
  &.page-content-margin-bottom {
    margin-bottom: $base-spacing-5x;
  }
  .page-content-title {
    color: $color-dark-trending;
    font-family: $font-family-merriweather-sans;
    font-size: $font-size-page-content-title;
    line-height: $line-height-page-content-title;
    margin-bottom: $base-spacing-3x;
    text-transform: uppercase;
    text-align: left;
  }
  background: $color-white;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
  padding: $base-spacing-10x;
  position: relative;
}

.page-content-description {
  color: $color-dark-trending;
  font-family: $font-family-merriweather-sans-light;
  font-size: $font-size-page-content-description;
  letter-spacing: 0.17px;
  line-height: $line-height-page-content-description;
  margin-bottom: $base-spacing-5x;
  text-align: left;
}

.page-content-locked {
  .edit-button,
  .text-modules,
  .text-container,
  input[type="text"] {
    color: $color-iron;
  }

  input[type="text"] {
    color: $color-iron;
    @include change_color_placeholder {
      color: $color-iron !important;
      opacity: 1;
    }
  }

  .preview-edit-icon {
    visibility: hidden;
  }

  input[type="radio"] {
    @include checkbox-radio-locked();
  }

  #dateContainer {
    color: $color-iron;
  }

  color: $color-iron;
  pointer-events: none;
}

.invalidRadio {
  + .radio-label {
    &:before {
      border: 2px solid $color-invalid !important;
    }
  }
}

.page-content-locked {
  .main-text-component-container {
    .main-carousel-container {
      .carousel-progress-items {
        .carousel-bullet {
          input[type="radio"] {
            @include checkbox-radio(
              $color-iron,
              $color-shuttle-gray,
              $color-shuttle-gray,
              $color-shuttle-gray,
              $color-shuttle-gray,
              12px,
              0,
              0
            );
          }
        }
      }
    }
  }
}
