.side-bar-row {
  display: flex;
  justify-content: space-between;
  border-top: solid #c2c6cc 1px;
  padding-top: 10px;
  padding-bottom: 10px;
  .value {
    text-align: right;
    max-width: 200px;
    word-wrap: break-word;
  }
}

.side-bar-row-line {
  border-bottom: solid #c2c6cc 1px;
}
