@import "../../../Styles/index.scss";

.header__links-container {
  display: flex;
  height: 100%;
  align-items: center;
  flex: 1 0 auto;
  .application-title {
    color: $color-blue-ribbon-2;
    margin: 0 20px;
    margin-right: auto;
    cursor: pointer;
    font-weight: $font-weight-bold;
    font-size: $font-size-application-title;
    outline: none;
    white-space: nowrap;
    text-decoration: none;
  }
  .page-links-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    .header__page-link.selected-link {
      color: $color-blue-ribbon-2 !important; /* override index styling */
      border-bottom: 5px solid $color-blue-ribbon-2;
    }
    .header__page-link {
      @include link($color-black, $color-black, none, none);
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      height: 100%;
      font-size: $font-size-settings-button;
      letter-spacing: 0.69px;
      cursor: pointer;
      padding: 0 20px;
      text-transform: uppercase;
      border-bottom: 5px solid transparent;
      padding-bottom: 10px;
    }
  }
}
