.container-left {
  .add-case-button {
    .btn-active,
    .btn-disabled {
      padding-left: 10px;
      padding-right: 10px;
      height: 41px; /* IE11 fix */
    }
  }
}
