@import "../bootstrap";
@import "sma_decoration";

html,
body {
  @include font-smoothing;
  font-family: $font-family-merriweather-sans;
  font-size: $font-size-body;
  height: 100%;
  margin: 0;
  padding: 0;
  width: 100%;
}

#app {
  background-color: $color-white;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  min-width: 100%;
}

input {
  @include change_color_placeholder {
    color: $color-dark-gray-1 !important;
    opacity: 1;
  }

  &:focus {
    @include change_color_placeholder {
      color: transparent !important;
    }

    @include no_shadow();
    border: 1px solid $color-blue-ribbon-1;
  }

  &::-ms-clear {
    display: none;
  }

  border: 1px solid $color-manatee;
  color: $color-dark-trending;
  font-family: $font-family-merriweather-sans;
  min-height: 54px;
  padding: $base-spacing-4x;
  width: 100%;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

div,
button {
  &:focus {
    outline: 0;
  }
}

button {
  background: none;
  border: none;
  cursor: pointer;
}

div.shadow {
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
}

label {
  align-items: center;
  align-self: flex-start;
  display: flex;
}

textarea {
  border: 1px solid $color-manatee;
  color: $color-dark-trending;
  font-family: $font-family-merriweather-sans;
  height: 150px;
  margin: $base-spacing-4x 0;
  max-height: 250px;
  padding: $base-spacing-5x;
  width: 100%;

  &:focus {
    @include change_color_placeholder {
      color: transparent !important;
    }

    @include no_shadow();
    border: 1px solid $color-blue-ribbon-1;
  }
}

// ---------------------------------
.hide {
  display: none;
}

.text-module {
  display: flex;
}

.disabled {
  background-color: $color-shuttle-gray;
}

.invalid {
  border: 1px solid $color-invalid;
}

.mandatory-hint {
  [class*="-locked"] & {
    color: $color-iron;
  }

  color: $color-oxford-blue;
}

.dropdown-divider {
  margin-bottom: $base-spacing-9x;
}

.info-icon-image {
  background: none;
  cursor: pointer;
  height: auto;
  margin-left: $base-spacing-4x;
  width: auto;
}

.close-icon-image {
  background: none;
  height: auto;
  margin-left: $base-spacing-4x;
  margin-right: $base-spacing-5x;
  width: auto;
}

.radio {
  display: flex;
  position: relative;
  margin-right: $base-spacing-5x;

  input[type="radio"] {
    @include checkbox-radio(
      $color-white,
      $color-blue-ribbon-1,
      $color-oxford-blue,
      $color-manatee,
      $color-white,
      24px,
      $base-spacing,
      1px
    );
    display: none;
    max-width: 24px;
    top: -14px;
  }
}

// Gradients
// ---------------------
.background-gradient1 {
  background: linear-gradient(
    225.26deg,
    $color-gradient1-1 0%,
    $color-gradient1-2 47.52%,
    $color-gradient1-3 96.82%,
    $color-gradient1-4 100%
  );
}

.background-gradient2 {
  background: linear-gradient(
    225.26deg,
    $color-gradient1-1 0%,
    $color-gradient1-2 47.52%,
    $color-gradient1-3 98.53%,
    $color-gradient1-4 100%
  );
  transform: scaleX(-1);
}

// Workflow
// -----------------------
.workflow-navigation {
  @include workflow();
}

.workflow-content {
  @include workflow($color-white);

  & .background-box {
    height: 261px;
    left: 0;
    position: absolute;
    right: 0;
    width: 100%;
  }

  display: flex;
  flex-direction: column;
  z-index: 0;
  color: black;
}

// Boxes
// ---------------------
.bottom-grey-box {
  @include box-decoration;
}

.one-row-two-inputs {
  display: flex;
  width: 100%;

  > div.radio:first-child {
    width: 120px;
  }

  #streetNumberContainer {
    margin-left: 5%;
    width: 50%;
  }

  #zipCodeContainer {
    margin-right: 5%;
    width: 40%;
  }

  #birthDateInputContainer {
    margin-right: 5%;
    width: 40%;
  }

  label + & {
    margin-top: $base-spacing-2x;
  }
}

// Links
// --------------------------
.link-in-case {
  @include link($color-blue-ribbon-1, $color-blue-ribbon-1, none, none);
  font-family: $font-family-merriweather-sans;
  font-size: $font-size-link-in-case;
  letter-spacing: 0.18px;
  line-height: $line-height-link-in-case;
  padding-right: 0;
}

.link-in-case-disabled {
  pointer-events: none;
  color: $color-iron;
}

.ReactModalPortal {
  z-index: 2000;
}

// @include media-breakpoint-up(xl) {
//   .workflow-content:after {
//     right: -$base-spacing-30x;
//     bottom: -$base-spacing-30x;
//   }
// }
// @include media-breakpoint-down(lg) {
//   .workflow-content:after {
//     right: 0;
//     bottom: 0;
//   }
//   .workflow-title {
//     text-align: center;
//   }
// }
