@import "../../sma-shared/Styles/index.scss";
@import "../../sma-shared/Styles/helpers/sma_fonts";

.input-card-container {
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  min-width: 445px;
  width: -webkit-fill-available;
  padding: 22px 40px 40px 30px;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.1);
  background-color: $color-white;
  font-size: 14px;
  margin-right: 40px;
  margin-bottom: 40px;
  .title {
    text-align: left;
    margin-bottom: 15px;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 14px;
  }
  .icon-general {
    margin-left: auto;
    cursor: pointer;
    width: 20px !important;
    height: 20px !important;
    margin-right: 0px;
    svg {
      height: 18px !important;
    }
  }
}

.input-card-container.disabled {
  pointer-events: none;
  opacity: 0.4;
  background-color: $color-white;
}

.mandatory {
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.1);
  background-color: $color-white;
}
