@import "../../../sma-shared/Styles/index.scss";
@import "../../../Utils/_utils.scss";

// Calculate from pixel to REM with base of 14px

.navigation-bar {
  width: 100vw; // ensure the navigation bar is the full width of the screen (not just the width of the page)
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: calculateRem(20px);
  padding-right: calculateRem(20px);
  background-color: #fff;
  min-height: calculateRem(76px);
  box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.1);
  z-index: 3;

  button {
    height: calculateRem(36px);
    font-family: HaufeMerriweatherSans;
    font-size: calculateRem(14px);
    line-height: calculateRem(14px);
    // padding: 10px 20px 10px 20px;
    padding-left: calculateRem(20px);
    padding-right: calculateRem(20px);
    padding-top: calculateRem(10px);
    padding-bottom: calculateRem(10px);
    border-radius: calculateRem(5px);
  }

  .navigation-bar-items {
    display: flex;
    > * + * {
      margin-left: calculateRem(20px); /* 20px */
    }

    .navigation-bar-action {
      border: 1px solid $color-royal-blue;
      color: $color-royal-blue;
      &:last-child {
        background-color: $color-royal-blue;
        color: #ffffff;
        border: 0;
      }
    }

    .feedback {
      border: 0;
      background-color: $color-royal-blue;
      color: #ffffff;
    }

    .left {
      background-color: $color-hawkes-blue;
      color: $color-tuna;
      &:last-child {
        background-color: $color-hawkes-blue;
        color: $color-tuna;
        border: 0;
      }
    }

    .disabled {
      cursor: not-allowed;
      background-color: #95979a;
      color: #ffffff;
      border: 0;
    }
  }
}

.navigation-bar-old {
  display: flex;
  padding: 20px 0px;
  box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.1);
  width: 100%;
  background-color: #fff;
  z-index: 3;
  min-height: 76px;

  button {
    height: 36px;
    font-family: HaufeMerriweatherSans;
    font-size: 14px;
    line-height: 16px;
    padding: 10px 20px 10px 20px;
    border-radius: 6px;
  }

  // weiter btn
  .navigation-bar-action-0 {
    background-color: $color-royal-blue;
    color: #ffffff;
    margin-right: 80px;
    order: 3;
  }

  // zurück btn
  .navigation-bar-action-1 {
    background-color: $color-hawkes-blue;
    color: $color-tuna;
    margin-left: 80px;
    margin-right: auto;
    order: 1;
  }

  // delegation btn
  .navigation-bar-action-2 {
    border: 1px solid $color-royal-blue;
    color: $color-royal-blue;
    margin-right: 20px;
    margin-left: auto;
    order: 2;
  }

  // delegation btn
  .navigation-bar-action-2-disabled {
    background-color: #95979a;
    color: #ffffff;
    margin-right: 20px;
    margin-left: auto;
    order: 2;
  }
  .navigation-bar-action-2-disabled:hover {
    cursor: not-allowed;
  }
}

// weiter btn
.navigation-bar.no-back-action {
  .navigation-bar-action-0 {
    margin-left: auto;
    margin-right: 80px;
    order: 1;
  }
}

.active-delegation-status-bar {
  position: absolute;
  top: 66px;
  z-index: 4;
  width: 100%;

  .status-bar {
    padding-left: 80px !important;
  }

  .edit-icon {
    padding: 0px;
    padding-left: 20px;
  }
  .edit-icon:first-child {
    padding-left: 0px;
  }
}
