@import "../../sma-shared/Styles/index.scss";

.formatted-text-container {
  span.paragraph {
    display: block;
    min-height: 15px;
  }

  span.link {
    color: #00abf7;
    cursor: pointer;
  }
}
