@import "../../sma-shared/Styles/index.scss";
.container-left {
  .title {
    height: 28px;
    width: 117px;
    color: $color-black-pearl;
    font-family: HaufeMerriweatherSans;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 28px;
    text-transform: uppercase;
    text-align: left;
    margin-bottom: 20px;
  }
}
.units-item {
  font-size: 14px;
  line-height: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  word-break: break-word;
  margin-bottom: 5px;
  height: 30px;
  span {
    margin-right: 20px;
  }

  .units-name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    flex: 1;
  }
}
.selected {
  color: #2072e6;
}
.displayNone {
  display: none;
}

.container-right {
  .page-height {
    min-height: 100%;
    .flex-container {
      min-height: 100%;
      .grey-container-without-sidebar {
        margin-bottom: -100px;
      }
    }
  }
}

// Safari-fix
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    .container-right {
      .page-height {
        min-height: -webkit-fit-content;
        .flex-container {
          min-height: -webkit-fit-content;
        }
        &.compliance {
          .grey-container-without-sidebar {
            height: -webkit-fill-available;
          }
        }
      }
    }
  }
}
