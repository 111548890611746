@import "../../Styles/index.scss";

.adv-banner-wrapper {
  & a {
    color: $color-white;
    line-height: 1.231;
    text-decoration: underline;
  }
  bottom: 0;
  color: $color-white;
  filter: Alpha(opacity=100);
  font-family: $font-family-merriweather-sans;
  max-height: none;
  max-width: none;
  min-height: 0;
  opacity: 1;
  min-width: 0;
  position: fixed;
  tab-size: 8;
  text-shadow: none;
  word-spacing: normal;
  width: 100%;
  white-space: normal;
  z-index: 2147483645;
}

.adv-banner-container-wrapper {
  background-color: $color-banner;
  height: 157px;
}

.adv-banner-forced-wrapper {
  background-color: $color-yellow;
}

.adv-banner-container {
  display: flex;
  flex-direction: column;
  font-size: $font-size-adv-banner;
  font-family: $font-family-merriweather-sans;
  max-width: 1442px !important;
  //max-width: 80%;
  margin-left: auto;
  margin-right: auto;
  padding-top: $base-spacing-5x;
  padding-right: $base-spacing-2x;
  padding-left: $base-spacing-14x;
  text-align: left;
  vertical-align: top;
}

.adv-banner-forced {
  color: $color-banner-forced;
  font-weight: $font-weight-bold;
  font-size: $font-size-adv-banner-forced;
  padding: $base-spacing-2x $base-spacing-16x;
}

.adv-forced-panel {
  background-color: $color-am-forced;
  bottom: 0;
  display: flex;
  outline: none;
  left: 0;
  right: 0;
  position: fixed;
  top: 0;
  z-index: 10000;
}

.adv-banner-header {
  & .adv-banner-title {
    flex: 1 1 auto;
  }
  display: flex;
  flex-direction: row;
}

.adv-banner-title {
  font-weight: $font-weight-bold;
  margin-bottom: $base-spacing-3x;
  text-transform: uppercase;
}

.adv-banner-close-icon {
  height: 12.3px;
  margin-bottom: $base-spacing-3x;
  width: 12.3px;
  color: $color-white;
}

.adv-banner-content {
  display: flex;
  flex-direction: row;
}

.adv-banner-text {
  flex: 1 1 auto;
  line-height: 21px;
  width: 80%;
}

.btn-adv-accepted {
  font-size: $font-size-adv-banner-button;
  font-weight: $font-weight-bold;
  height: 46px;
  min-width: 163px;
  margin-left: $base-spacing-11x;
  margin-right: $base-spacing-12x;
  padding: 0px;
  border: 1px solid $color-white;
  background-color: $color-white;
  color: #515353;
  cursor: pointer;
}

.btn-adv-not-accepted {
  font-size: $font-size-adv-banner-button;
  font-weight: $font-weight-bold;
  height: 46px;
  min-width: 163px;
  margin-left: $base-spacing-11x;
  margin-right: $base-spacing-12x;
  padding: 0px;
  background-color: $color-white;
  color: #515353;
  cursor: pointer;
}

/* Transition styling */
.fade-enter {
  opacity: 0.01;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.fade-leave {
  opacity: 1;
}

.fade-leave.fade-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}
