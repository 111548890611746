.container.error {
  margin-top: $base-spacing-12x;
}

.content-container {
  display: flex;
}

.page-container {
  &.extra-bottom {
    margin-bottom: $base-spacing-15x;
  }

  .page-header {
    &.no-bottom {
      margin-bottom: 0;
    }

    .page-header-title {
      font-size: $font-size-page-header-title;
      line-height: $line-height-page-header-title;
      font-family: $font-family-merriweather-sans;
      text-transform: uppercase;
    }

    .page-header-description {
      font-family: $font-family-merriweather-sans;
      font-size: $font-size-page-header-description;
      line-height: $line-height-page-header-description;
    }

    color: $color-white;
    height: 130px;
    margin-bottom: $base-spacing-6x;
    width: 100%;
  }

  position: relative;
  margin-left: $base-spacing-15x;
  margin-bottom: $base-spacing-12x;
  margin-top: $base-spacing-9x;
  z-index: 1;
}

.page-container,
.page-container.page-content-locked,
.page-button-container {
  width: 884px;
}

// [class~='page-input-container']:last-of-type {
//   margin-bottom: 0;
// }

.page-input-container {
  label {
    font-size: $font-size-page-input-container-label;
    font-weight: $font-weight-bold;
    margin-bottom: $base-spacing;
    text-transform: uppercase;
  }

  display: flex;
  flex-direction: column;
  margin-bottom: $base-spacing-9x;
  width: 100%;
}

.page-input-container:last-child {
  margin-bottom: 0;
}

.input-container {
  margin: $base-spacing-2x;
  padding: $base-spacing-2x;
  width: 50%;
}

.text-container {
  margin-left: 2.5%;
  margin-right: 2.5%;
  text-align: left;
  width: 75%;
}
