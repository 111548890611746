@import "../../../../sma-shared/Styles/helpers/sma_variables";

.dropdown-icon {
  background: none;
  display: flex;
  height: 6px;
  width: 10px;
  margin-right: 10px;
}

.dropdown-input-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.dropdown-input-container > div {
  width: 100%;
}

.dropdown-input-container input {
  position: static;
}

.dropdown-input-container input[readonly] {
  font-size: 0;
}

.dropdown-input-container.invalid {
  border: none;
}

.hidden-dropdown-details {
  width: 10% !important;
}

.dropdown-error-message {
  color: $color-invalid;
  font-size: 12px;
  height: 100%;
  position: relative;
  padding-top: 8px;
  width: 520px; /* works best for Falldaten error message */
  text-align: left;
}
