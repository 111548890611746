@import "../../sma-shared/Styles/index.scss";

.summary-attachments-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  .summary-file-row {
    display: flex;
    width: -webkit-fill-available;
  }
  button {
    margin-left: auto;
  }
}
