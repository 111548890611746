@import "../../sma-shared/Styles/index.scss";

.checkbox-container {
  display: flex;
  flex-direction: column;
  input[type="checkbox"] {
    @include checkbox-filter();
  }
}

.checkbox-container input[type="checkbox"] + .checkbox {
  background-color: #ffffff;
  border: 1px solid #b3b3b3;
}

.checkbox-container.invalid {
  border: none;
}

.checkbox-container.invalid input[type="checkbox"] + .checkbox {
  border: 2px solid $color-invalid;
}

.checkbox-container input[type="checkbox"]:checked + .checkbox {
  background-color: $color-royal-blue;
}

.checkbox-container input[type="checkbox"]:checked + .checkbox:after {
  color: #ffffff;
}

.checkbox-container
  input[type="checkbox"]:checked
  + .checkbox
  + .checkbox-text {
  color: $color-royal-blue;
}
.checkbox-container input[type="checkbox"] + label {
  margin-left: 0px;
  margin-top: auto;
}

.checkbox-container {
  label {
    cursor: pointer;
    margin-bottom: auto;
    font-family: "HaufeMerriweatherSans", sans-serif;
    &[disabled] {
      pointer-events: none;
      cursor: default;
      opacity: 0.6;
    }
  }
  div {
    display: flex;
  }
}
