.input-card-container .radioButton + div.text-input-container {
  margin-top: 20px;
}

#gift-source-label {
  display: none;
}

.gift-container {
  .value-type-container {
    display: flex;
    margin-right: 10px;
    padding: 10px 0px 19px 0;
    .radioButton {
      margin-right: 30px;
    }
  }
}
