.notification-container {
  top: auto;
  margin-bottom: 15px;
  div {
    color: #1b2026;
  }
  .notification-success {
    background-color: #1fb218;
  }
}
