@import "../../sma-shared/Styles/index.scss";
.autosuggest-container {
  margin-bottom: 20px;
}
.text-error-message {
  color: $color-invalid;
  font-size: 12px;
  height: 100%;
  position: relative;
  padding-top: 8px;
  width: 100%;
  text-align: left;
}
.react-autosuggest__suggestions-container {
  display: none;
  position: absolute;
  color: $color-tuna;
  font-family: $font-name-merriweather-sans-light;
  font-size: 14px;
  line-height: 2;
  max-height: 200px;
  overflow: auto;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  border: 1px solid $color-hawkes-blue;
  border-radius: 0.25rem;
  background-color: $color-white;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  color: $color-tuna;
  padding: 5px 14px;
  text-align: left;
}

.react-autosuggest__suggestion:last-child {
  border: none;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}
