.admin-data {
  position: relative;
  .admin-data-icon {
    border-radius: 50%;
    width: 45px;
    height: 45px;
    color: white;
    background-color: #27889a;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &.colored {
      background-color: #2072e6;
    }
    svg {
      max-width: 22.5px;
      height: auto;
    }
  }
  .admin-data-box {
    display: flex;
    flex-direction: column;
    text-align: left;
    position: absolute;
    top: 50px;
    right: 0;
    background-color: white;
    padding: 2em;
    width: 350px;
    box-shadow: 0 4px 5px 2px #bfbfbf;
    border-radius: 4px;
    opacity: 0;
    visibility: hidden;
    transition: all 0.125s ease;
    &.show-popup {
      opacity: 1;
      visibility: visible;
    }
    .close-box {
      display: flex;
      align-items: flex-end;
      align-self: flex-end;
      flex-direction: column;
      position: relative;
      right: -15px;
      top: -7.5px;
      cursor: pointer;
      span {
        display: block;
        width: 17.5px;
        background-color: darken(#dedede, 15%);
        height: 2px;
        margin-bottom: 2px;
        &:first-child {
          transform: rotate(45deg) translateY(3px);
        }
        &:last-child {
          transform: rotate(-45deg) translateY(-3px);
        }
      }
    }
    .content {
      max-height: 600px;
      overflow: auto;
      h2 {
        text-transform: uppercase;
        font-size: 1rem;
        font-weight: bold;
        margin-bottom: 1.5em;
      }
      p {
        margin-bottom: 0;
        user-select: text;
      }
      .admin-info {
        padding: 5px 0px;
      }
    }
  }
}
