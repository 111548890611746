@import "../../../../Styles/index.scss";

.profile-menu__container {
  background-color: #fff;
  border: 1px solid hsla(222, 5%, 58%, 0.23);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  padding: 8px;
  position: absolute;
  right: 40px;
  text-align: left;
  top: 60px;
  text-transform: uppercase;
  width: 215px;
  z-index: 2;
  * {
    color: $color-oxford-blue !important;
    cursor: pointer;
    display: inline-block;
    outline: none;
    padding: 20px 8px;
    &:hover {
      background-color: $color-mischka-4;
    }
  }
}
