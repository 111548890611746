@import "sma_fonts";
@import "sma_colors";

// special fonts
$font-family-babas: $font-name-babas, sans-serif;

$font-family-merriweather: $font-name-merriweather, serif;
$font-family-merriweather-light: $font-name-merriweather-light, serif;
$font-family-merriweather-xlight: $font-name-merriweather-xlight, serif;

$font-family-merriweather-sans: $font-name-merriweather-sans, sans-serif;
$font-family-merriweather-sans-light: $font-name-merriweather-sans-light,
  sans-serif;
$font-family-merriweather-sans-xlight: $font-name-merriweather-sans-xlight,
  sans-serif;

// ---------------------------
// Font Sizes and Line Heights
// ----------------------------

// Font weight variables
$font-weight-light: 300;
$font-weight-regular: 500;
$font-weight-bold: 700;

$font-style-italic: italic;

// font sizes
$font-size-body: 14px;

$font-size-page-header-title: 36px;
$line-height-page-header-title: 1.48;

$font-size-page-header-description: 16px;
$line-height-page-header-description: 1.5;

$font-size-link-in-case: 14px;
$line-height-link-in-case: 1.3;

$font-size-page-content-title: 26px;
$line-height-page-content-title: 32px;

$font-size-page-input-container-label: 12px;

$font-size-check-create-case: 18px;
$line-height-check-create-case: 18px;

$font-size-casecheck-question-text: 16px;
$font-size-casecheck: 14px;

$font-size-filter-tag: 12px;

$font-size-filter-options-title: 12px;

$font-size-regular-checkbox: 16px;

$font-size-adv-banner: 14px;
$font-size-adv-banner-forced: 14px;

$font-size-ping-error-container: 12px;

$font-size-footer: 8pt;

$font-size-application-title: 14px;
$line-height-application-title: 4.9;

$font-size-error-message-input-container: 12px;

$font-size-profile-container: 14px;
$line-height-profile-container: 66px;

$font-size-full-name-container: 12px;
$line-height-full-name-container: 5.6;

$font-size-user-initials-container: 16px;
$line-height-user-initials-container: 1.25;

$font-size-info-box-container: 13px;
$line-height-info-box-container: 21px;

$font-size-info-header-container: 16px;

$font-size-modal-header-container: 16px;

$font-size-print-format-header: 16px;
$line-height-print-format-header: 32px;

$font-size-page-content-description: 14px;
$line-height-page-content-description: 22px;

$font-size-input-group-text: 14px;
$line-height-input-group-text: 18px;

$font-size-form-group-label: 14px;
$line-height-form-group-label: 18px;

$font-size-paceholder: 16px;

$font-size-text-module: 16px;
$line-height-text-module: 23px;

$font-size-preview-page: 11pt;
$font-size-preview-sender: 7pt;

$font-size-workflow-title: 20px;
$line-height-workflow-title: 29px;

$font-size-nav-text: 14px;

// --------- dropdown ----------------
$font-size-dropdown-details: 12px;
$line-height-dropdown-details: 54px;

// ------------ input -----------
$font-size-input-radio: 14px;
$line-height-input-radio: 18px;

// ------------- table ---------------------
$font-size-grid-table: 11px;

$font-size-react-table-header: 12px;

$font-size-name-column: 14px;

// ---------- buttons ---------------
$font-size-button-solid: 18px;
$line-height-button-solid: 23px;

$font-size-button-ghost: 18px;
$line-height-button-ghost: 23px;

$font-size-main-page-button: 14px;

$font-size-adv-banner-button: 14px;

$font-size-settings-button: 12px;
$line-height-settings-button: 5.6;

$font-size-social-icon-button: 16px;
$line-height-social-icon-button: 42px;

$font-size-modal-button: 14px;

$font-size-button-container-description: 14px;

$font-size-button-arrow: 20px;

$font-size-back-button-container: 14px;
$line-height-back-button-container: 21px;

$font-size-company-profile-button: 14px;
$line-height-company-profile-button: 1.3;

$font-size-delete-button: 16px;
$line-height-delete-button: 20px;

$font-size-create-button: 14px;

// content
$contentContainerWidth: 750px;
$arrowSize: 50px;

// decorative patterns
$solid-pattern-width: 380px;
$solid-pattern-height: 380px;

$solid-pattern-page-content-width: 50%;
$solid-pattern-page-content-height: 30%;

$solid-pattern-case-width: 80%;
$solid-pattern-case-height: 40%;

// -------------------------------
// Grid
// -------------------------------
$grid-gutter-width: 24px;
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1320px,
  xxl: 1440px,
);
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1450px,
);
$grid-breakpoints-custom: (
  lg-mid: 1100px,
);
$min-width-fluid: map-get($grid-breakpoints, sm);
$max-width-fluid: map-get($grid-breakpoints, lg);
$max-width-fluid-xl: map-get($grid-breakpoints, xl);
$max-width-fluid-xxl: map-get($grid-breakpoints, xxl);
$min-heigh-fluid: 0px;
$max-heigh-fluid: map-get($grid-breakpoints, md);
// -------------------------------
// Spacings
// -------------------------------
$base-spacing: 4px;
$base-spacing-0x: $base-spacing * 0;
$base-spacing-2x: $base-spacing * 2;
$base-spacing-3x: $base-spacing * 3;
$base-spacing-4x: $base-spacing * 4;
$base-spacing-5x: $base-spacing * 5;
$base-spacing-6x: $base-spacing * 6;
$base-spacing-7x: $base-spacing * 7;
$base-spacing-8x: $base-spacing * 8;
$base-spacing-9x: $base-spacing * 9;
$base-spacing-10x: $base-spacing * 10;
$base-spacing-11x: $base-spacing * 11;
$base-spacing-12x: $base-spacing * 12;
$base-spacing-14x: $base-spacing * 14;
$base-spacing-15x: $base-spacing * 15;
$base-spacing-16x: $base-spacing * 16;
$base-spacing-17x: $base-spacing * 17;
$base-spacing-18x: $base-spacing * 18;
$base-spacing-19x: $base-spacing * 19;
$base-spacing-20x: $base-spacing * 20;
$base-spacing-24x: $base-spacing * 24;
$base-spacing-30x: $base-spacing * 30;
