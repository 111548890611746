// Primary Colors and Grey Colors of Digital
// ---------------------------------------------
$color-alice-blue: #f5f7fa; // Alice Blue
$color-cerulean-2: #00abf7; // Cerulean-2
$color-blue-ribbon-1: #075bfa; // Blue Ribbon-1
$color-blue-ribbon-2: #075bf9; // Blue Ribbon-2
$color-royal-blue: #2072e6; // Royal Blue
$color-regal-blue: #003f71; // Regal Blue
$color-regal-blue-2: #003f72;

$color-white: #ffffff; // White
$color-gallery: #eeeeee; // Gallery
$color-mischka-4: #f7f7f8; // Mischka-4
$color-mischka-3: #e7e7e8; // Mischka-3
$color-dark-gray-1: #8e8e8e; // Dark gray
$color-iron: #d2d4d6; // Iron
$color-manatee: #8f9299; // Manatee
$color-shuttle-gray: #636871; // Shuttle Gray
$color-tuna: #45484d; // Tuna
$color-oxford-blue: #232a36; // Oxford Blue
$color-hawkes-blue: #dfe1e6; // Hawkes Blue
$color-black-pearl: #1b2026; // Black Pearl
$color-black: #000000; // Black
$color-light-black: #3c3c3c; // Light Black
$color-dark-gray: #33332e; // Dark Gray
$color-silver: #c2c2c2; // Silver
$color-dark-trending: #262e33;
$color-dark-shadow: rgba(0, 0, 0, 0.1);
$color-am-forced: rgba(0, 0, 0, 0.34);
$color-white-transparent: rgba(255, 255, 255, 0.38);

// Validation Colors
// --------------------------------------------
$color-invalid: #e81446;

// Other Colors
// -------------------------------------------
$color-orange: #f4a730;
$color-notificaiton: #ffae03;
$color-gray: #b3b3b3;
$color-banner: #979797;
$color-yellow: #f8e71c;
$color-banner-forced: #515353;
$color-green-blue: #29889a;
$color-red: #f1708e;
$color-select: rgba(0, 88, 255, 0.2);
$color-blur: rgba(0, 0, 0, 0.5);

// Gradient Colors
// ------------------------------------------
$color-gradient1-1: #0066de;
$color-gradient1-2: #00adee;
$color-gradient1-3: #00e7e0;
$color-gradient1-4: #00ebdf;
