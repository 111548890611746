@import "../../sma-shared/Styles/helpers/sma_fonts.scss";

.error-message-section {
  background: #e63120;
  border-radius: 4px;
  padding: 13px 20px;
  display: flex;
  flex-direction: column;
  margin-bottom: 60px;

  text-align: left;

  span {
    font-family: $font-name-merriweather-sans-light;
    color: #ffffff;
    font-size: 14px;
  }
}
