@import "../../../sma-shared/Styles/index.scss";
@import "../../../sma-shared/Styles/helpers/sma_colors";
@import "../../../sma-shared/Styles/helpers/sma_fonts.scss";

.copyable-area {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 1em;
  margin-top: -15px;
  span.copyable-text {
    width: 100%; /* IE11 fix*/
    font-size: 14px;
    color: $color-black-pearl;
    text-align: left;
    margin-bottom: 1.5em;
    padding: 1em;
    border: 1px solid $color-hawkes-blue;
  }
  button.copy-text-button {
    color: $color-royal-blue;
    display: flex;
    align-items: center;
    height: 30px; /* ie11 fix*/
    svg {
      fill: $color-royal-blue;
      width: 15px;
      height: auto;
      margin-right: 10px;
    }
    span {
      display: block;
      padding-bottom: 1.5px;
      border-bottom: 1px solid $color-royal-blue;
      color: $color-royal-blue;
    }
  }
}

.modal-content-container {
  padding-bottom: 10px !important;
  .input-card-container {
    box-shadow: none;
    padding: 1em 0;
    width: 100%;
    margin-bottom: 0;
    textarea {
      resize: none;
      height: 155px;
    }
  }
  .buttons-area {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-bottom: 1em;
    button {
      max-width: fit-content;
      border-radius: 4px;
      &:first-of-type {
        margin-right: 1em;
        color: $color-royal-blue;
        border: 1px solid $color-royal-blue;
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        line-height: 1.5;
        height: auto;
        width: 100px; // fix for IE11
      }
      &.button-disabled {
        opacity: 0.7;
        cursor: not-allowed;
        pointer-events: none;
      }
    }
  }
}
.invalid-message-container {
  color: #e81446;
  font-size: 12px;
  height: 100%;
  position: relative;
  padding-top: 8px;
  width: 100%;
  text-align: left;
}
.approval-container {
  width: 620px;
}
.recommendations {
  text-align: left;
  color: $color-tuna;
}
