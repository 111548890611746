@import "../../sma-shared/Styles/index.scss";

.modal-confirm.evaluation-help-modal {
  width: 920px;
  .modal-content-container {
    max-height: calc(80vh);
    overflow: auto;
  }
}

.modal-confirm.operation-info-modal {
  width: 700px;
}
