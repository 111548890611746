@import "../../sma-shared/Styles/index.scss";

.ReactModalPortal {
  position: relative;
  z-index: 99;
}

.modal-confirm {
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  text-align: left;
  font-family: $font-family-merriweather-sans-light;
  font-size: 14px;
  line-height: 24px;
  background-color: $color-white;
  width: auto;
  min-width: 480px;
  min-height: 200px;
  margin: auto;
  max-width: 800px;

  p {
    padding: 20px;
  }

  .saved {
    display: none;
  }

  .close {
    display: flex;
    margin: -10px -10px 0px auto;
    img {
      height: 12px;
      width: 12px;
      float: right;
    }
  }

  .modal-content-container {
    padding: 40px;

    .modal-header-container {
      height: 25px;
      font-size: 20px;
      color: $color-black-pearl;
      margin-bottom: 30px;
    }
  }

  .modal-button-container {
    display: flex;
    flex-direction: row;
    border-top: $color-hawkes-blue solid 1px;
    padding: 20px;
    bottom: 0;
    justify-content: flex-end;

    button {
      height: auto;
      font-size: $font-size-modal-button;
      line-height: 16px;
      padding: 10px 20px;
      border-radius: 6px;
      text-transform: none;

      &[disabled] {
        pointer-events: none;
        cursor: default;
        opacity: 0.3;
      }
    }

    input {
      border: $color-hawkes-blue solid 1px;
      border-radius: 6px;
    }

    .modal-btn,
    .btn-ghost {
      width: auto;
    }

    .secondary-button {
      font-family: $font-family-merriweather-sans;
      border: 1px solid $color-royal-blue;
      color: $color-royal-blue !important;
      margin-right: 20px;
      order: 1;

      &:focus,
      &:hover,
      &:visited,
      &:active {
        color: $color-royal-blue !important;
        /* override shared styles */
      }
    }

    .primary-button {
      font-family: $font-family-merriweather-sans;
      background-color: $color-royal-blue;
      color: #fff !important;
      order: 2;

      &:focus,
      &:hover,
      &:visited,
      &:active {
        color: $color-white !important;
        /* override shared styles */
      }
    }
  }
}

.modal-confirm.warning {
  max-width: 600px;
}

.backdrop {
  align-items: center;
  background-color: $color-blur;
  bottom: 0;
  display: flex;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
}

.modal-checkbox-container {
  input[type="checkbox"] {
    @include checkbox-filter();
  }

  label {
    cursor: pointer;
  }

  .checkbox-text {
    color: $color-oxford-blue;
    max-height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  display: flex;
  width: 100%;
}
