.dataProtection {
  padding: 0 40px 40px 40px;
  font-family: "Open Sans";
  text-align: initial;

  h1 {
    font-size: 34px;
    font-weight: bold;
    margin-top: 60px;
  }
  h2 {
    font-size: 25px;
    font-weight: bold;
    margin-top: 30px;
  }
  h3 {
    font-size: 20px;
    font-weight: bold;
    margin-top: 20px;
  }
  h4 {
    font-size: 15px;
    font-weight: bold;
    font-style: italic;
    margin-top: 20px;
  }
  h5 {
    font-size: 12px;
    font-weight: bold;
    font-style: italic;
    margin-top: 20px;
  }
  h6 {
    font-size: 10px;
    font-style: italic;
    margin-top: 15px;
  }
  p {
    font-size: 1.1rem;
    margin: 5px;
    color: #0c366b;
  }
  li {
    font-size: 1.1rem;
    color: #0c366b;
  }
}
