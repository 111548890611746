@import "../../../sma-shared/Styles/helpers/sma_colors";
@import "../../../sma-shared/Styles/helpers/sma_fonts.scss";

.workflow-actions {
  display: flex;
  flex-direction: column;
  font-family: $font-name-merriweather-sans-light;
  font-size: 14px;
  line-height: 24px;
  .action-container {
    color: $color-royal-blue;
    padding: 0;
    display: flex;
    align-items: center;
  }
  .action-icon {
    height: 14px;
    width: 14px;
  }
  .action-name {
    padding-left: 20px;
  }
}
