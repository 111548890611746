.btn-solid {
  @include btn-solid();
  font-family: $font-family-merriweather-sans;
  font-size: $font-size-button-solid;
  height: 55px;
  line-height: $line-height-button-solid;
  width: 100%;
}
.btn-orange {
  @include btn-solid($color-orange);
  height: 55px;
  width: 100%;
}

.modal-btn {
  @include btn-solid($color-white, $color-manatee);
  font-size: $font-size-modal-button;
  text-transform: uppercase;
  height: 55px;
  width: 100%;
}

.btn-ghost,
.btn-ghost.disabled {
  @include btn-transparent(
    $color-blue-ribbon-1,
    $color-blue-ribbon-1,
    $color-blue-ribbon-1,
    $color-white
  );
  font-family: $font-family-merriweather-sans;
  font-size: $font-size-button-ghost;
  height: 55px;
  line-height: $line-height-button-ghost;
  width: 100%;
}
.btn-ghost > img {
  position: relative;
  margin-top: -$base-spacing;
  margin-right: $base-spacing-2x;
}
.btn-icon-image {
  background: none;
  height: 20px;
  width: 20px;
}
.btn-toggle-arrow {
  @include btn-toggle-arrow();
}
