input[type="text"] {
  &[disabled] {
    opacity: 0.6;
  }
}
.optional {
  color: #8f9299;
  font-style: italic;
  padding-left: 5px;
}
