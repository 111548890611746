@import "../../sma-shared/Styles/index.scss";
@import "../../sma-shared/Styles/helpers/sma_fonts";

.label-container {
  text-align: left;
  display: flex;
  flex-direction: column;
  label:first-child {
    color: $color-tuna;
    font-family: $font-name-merriweather-sans-light;
    font-size: 14px;
    margin-bottom: 20px;
    display: block;
    width: 100%; /* fix for ie11*/
  }
  :last-child:not(:first-child) {
    margin-bottom: 20px;
  }
}
