@import "../../../sma-shared/Styles/index.scss";

.menu-container {
  border-radius: 6px;
  background-color: #ffffff;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.1);
  position: absolute;
  z-index: 1;
  right: 20px;
  padding: 15px 20px;
  font-family: $font-family-merriweather-sans-light;
  font-size: 14px;
  line-height: 24px;
  color: $color-black-pearl;

  ul {
    list-style-type: none;
    padding: 0px;
    margin: 0px;
    li {
      height: 30px; /* IE11 Fix */
      align-items: center;
      cursor: pointer;
      display: flex;
      padding-bottom: 6px;
      svg {
        height: 14px;
        width: 14px;
        margin-right: 10px;
      }
    }
  }
}

.row-over {
  bottom: 34px;
}
