@import "../../sma-shared/Styles/index.scss";

table {
  border-spacing: 0;
  width: 100%;
  color: $color-tuna;

  thead {
    display: table;
    width: 100%;
    table-layout: fixed;
    font-weight: normal;
    font-size: 14px;
    letter-spacing: 0.25px;
    text-align: left;
    background: $color-hawkes-blue;
    border-radius: 6px;

    tr {
      border: none;
    }

    th {
      margin: 0;
      padding: 0.5em 1em;
      font-family: $font-name-merriweather-sans-light;
      font-weight: 300;
      font-size: 12px;
      color: $color-black-pearl;
    }
  }

  tbody {
    display: block;
    table-layout: fixed;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    border-radius: 2px;
    background: $color-white;
    tr {
      display: table;
      width: 100%;
      table-layout: fixed;
      border-bottom: 1px solid $color-hawkes-blue;
      border-radius: 2px;
      font-family: $font-name-merriweather-sans;
      color: $color-black-pearl;
      border-radius: 6px;
      &:hover,
      &:active,
      &:focus {
        background-color: lighten(#fafafa, 0);
      }

      td {
        margin: 0;
        padding: 0.35em 1em;
        text-align: left;
        font-weight: normal;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 14px;
        color: #45484d;
        .menu-container {
          svg {
            max-width: 14px;
            height: auto;
            margin-right: 6px;
          }
        }
        input {
          height: 36px;
          min-height: 36px;
          border: 1px solid $color-hawkes-blue;
          border-radius: 4px;
          background: $color-white;
          width: 100%;
          padding: 10px;
          font-size: 14px;
          &[disabled] {
            opacity: 0.6;
          }
        }
        .error {
          border: 1px solid $color-red;
        }
      }
    }
  }
  .arrow-image {
    width: 12px;
    height: 12px;
    margin-left: 5px;
    margin-bottom: 2px;
  }
  .checkbox-container {
    padding-bottom: 0;
  }
}
