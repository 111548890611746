@import "../../../sma-shared/Styles/index.scss";

.container-left {
  .add-case-button {
    margin-bottom: 3em;
    .btn-disabled {
      color: $color-white;
      background-color: $color-gray;
      width: 100%;
      padding: 10px 26px;
      border-radius: 5px;
      cursor: not-allowed;
    }
    svg {
      max-width: 14px;
      height: auto;
      color: $color-white;
      margin-right: 6px;
    }
    .btn-active {
      height: 41px; /* IE11 Fix */
      color: $color-white;
      background-color: $color-blue-ribbon-1;
      width: 100%;
      padding: 10px 26px;
      border-radius: 5px;
      transition: all 0.3s ease;
      &:hover {
        background-color: lighten($color-blue-ribbon-1, 10%);
      }
    }
  }

  .gift-link {
    text-align: left;
    margin-bottom: 30px;
    position: relative;
    overflow: visible;
    .gift-link-header {
      display: flex;
      align-items: center;
      margin-bottom: 24px;
      height: 20px; /* IE11 fix */

      h2 {
        font-family: $font-name-merriweather-sans;
        font-size: 14px;
        text-transform: uppercase;
        font-weight: 600;
        color: $color-black-pearl;
        margin-bottom: 0;
      }
      svg {
        margin-left: auto;
        width: 18px;
        height: auto;
        stroke-width: 2;
        path {
          stroke-width: 100;
        }
        cursor: pointer;
      }
      .help-icon {
        margin-left: auto;
        align-self: end;
      }
    }
    position: relative;

    .invitation-tooltip {
      opacity: 0;
      visibility: hidden;
      position: absolute;
      top: 22%;
      left: 20%;
      background-color: $color-blue-ribbon-1;
      color: $color-white;
      font-size: 10px;
      padding: 3px 5px;
      border-radius: 4px;
      transition: all 0.3s ease;
      width: fit-content;
      &::before {
        content: "";
        width: 0;
        height: 0;
        border-top: 7px solid $color-blue-ribbon-1;
        border-right: 7px solid transparent;
        border-left: 7px solid transparent;
        position: absolute;
        left: 15px;
        bottom: -7px;
      }
      &.display-tooltip {
        opacity: 1;
        visibility: visible;
      }
    }
    .inivitation-link {
      color: $color-blue-ribbon-1;
      font-weight: 400;
      font-family: $font-name-merriweather-sans-light;
      font-size: 1rem;
      line-height: 24px;
      &:hover {
        + .invitation-tooltip {
          display: block;
        }
        cursor: pointer;
      }
    }
  }
}
