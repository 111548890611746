@import "../../sma-shared/Styles/index.scss";

.filter-container {
  text-align: left;
  #filterTag {
    color: $color-oxford-blue;
    font-weight: $font-weight-bold;
    margin-bottom: $base-spacing-5x;
    text-transform: uppercase;
  }
}

.filter-options-container {
  margin-bottom: $base-spacing-7x;
  font-family: $font-name-merriweather-sans-light;

  .circle {
    border: 1px solid $color-manatee;
    border-radius: 16px;
    height: 16px;
    width: 16px;
    cursor: pointer;
  }

  .doughnut {
    border: 3px solid $color-blue-ribbon-2;
    border-radius: 16px;
    height: 16px;
    width: 16px;
    cursor: pointer;
  }
}

.search-input-container {
  align-items: center;
  background-color: $color-white;
  padding: 14px 15px 14px 20px;
  display: flex;
  position: relative;
  border: 1px solid $color-hawkes-blue;
  border-radius: 4px;
  input.search-input {
    min-height: 24px;
    height: 24px;
    padding: 4px;
    border: 0;
  }
  .icon-general {
    height: 14px;
    width: 14px;
    &:hover {
      cursor: pointer;
    }
  }

  input[type="text"] {
    &::-webkit-input-placeholder {
      font-family: $font-name-merriweather-sans;
      color: $color-manatee;
      font-style: italic;
    }

    &::-moz-placeholder {
      font-family: $font-name-merriweather-sans;
      color: $color-manatee;
      font-style: italic;
    }

    &:-ms-input-placeholder {
      font-family: $font-name-merriweather-sans;
      color: $color-manatee;
      font-style: italic;
    }

    &::-ms-input-placeholder {
      font-family: $font-name-merriweather-sans;
      color: $color-manatee;
      font-style: italic;
    }

    &::placeholder {
      font-family: $font-name-merriweather-sans;
      color: $color-manatee;
      font-style: italic;
    }
  }

  .search-icon-container {
    align-self: center;
    height: 100%;
    padding-top: $base-spacing;
    padding-bottom: $base-spacing-2x;
    width: 20px;

    img {
      background: none;
      display: flex;
      max-height: 20px;
      max-width: 20px;
    }
  }
}
