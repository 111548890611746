@import "../../../sma-shared/Styles/index.scss";
@import "../../../sma-shared/Styles/helpers/sma_fonts.scss";

$color-green-approved: #1fb218;
$color-red-rejected: #e63120;
.container-right {
  .overview-page-header {
    max-height: 43px;
    display: flex;
    flex-direction: row;
    align-items: baseline;
    margin-bottom: 20px;
    .title {
      font-size: 20px;
      font-family: HaufeMerriweatherSansLt;
    }
    .overview-button-bar {
      margin-left: auto;
    }
    .text-button {
      color: $color-royal-blue;
      font-family: "HaufeMerriweatherSans";
      letter-spacing: 0px;
      font-size: 14px;
      cursor: pointer;
      margin-left: 20px;
    }
    .inactive {
      color: $color-royal-blue;
      opacity: 0.5;
      pointer-events: none;
    }

    .icon-general {
      cursor: pointer;
      display: inline;
      svg {
        height: 13px;
        width: 13px;
        margin-right: 5px;
      }
    }
  }

  table.gifts-overview {
    thead {
      tr {
        th:nth-child(4) {
          width: 175px;

          div {
            justify-content: center;
          }
        }
        th:last-child {
          width: 50px;
        }
      }
    }

    tbody {
      tr {
        .rejected {
          color: $color-tuna;
        }

        td:nth-child(1),
        td:nth-child(2),
        td:nth-child(3) {
          color: $color-royal-blue;
        }
        td:nth-child(6) .rejected {
          color: $color-red-rejected;
        }
        td:nth-child(4) {
          width: 175px;
          text-align: right;
        }
        td:last-child {
          width: 50px;
        }

        td:nth-child(6) .completed {
          color: $color-green-approved;
        }
      }
    }
  }
}
