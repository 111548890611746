@import "../../sma-shared/Styles/index.scss";
.product-video {
  text-align: left;
  .product-video-tag {
    color: $color-black-pearl;
    font-weight: 700;
    margin-bottom: 20px;
    text-transform: uppercase;
  }
  button {
    padding: 0px;
    text-align: left;
    color: $color-royal-blue;
    font-family: HaufeMerriweatherSansLt;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 24px;
    padding-bottom: 10px;
  }
}
