@import "../../sma-shared/Styles/index.scss";

.text-input-container {
  .text-area {
    border-radius: 4px;
    border: 1px solid $color-hawkes-blue;
    min-height: 130px;
    padding: 16px 20px;
    margin: 0px;
    &::placeholder {
      color: #8e8e8e;
    }
  }
  .text-area:-ms-input-placeholder {
    color: #8e8e8e;
  }
}
