@import "../../../sma-shared/Styles/helpers/sma_colors";

.bericht-container {
  .input-card-container {
    width: 600px;
  }
  .falldaten-details {
    width: 600px;
    .input-card-container {
      max-width: 600px;
      background-color: #f5f7fa;
      box-shadow: none;
    }
  }
  .approvedby-label {
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: left;
  }
  #receiver-sidebar {
    color: $color-royal-blue;
  }
}

.feedback-dialog-container {
  label.Mui-focused {
    padding-left: 0.5em;
    padding-right: 0.5em;
    background-color: white;
  }
  .MuiInputLabel-root {
    top: -4px;
    padding-left: 0.5em;
    padding-right: 0.5em;
    background-color: white;
  }
}
