@import "../../../sma-shared/Styles/helpers/sma_colors";
@import "../../../sma-shared/Styles/helpers/sma_fonts.scss";

table.multiple-receiver {
  thead {
    tr {
      th:last-child {
        width: 50px;
      }
    }
  }

  tbody {
    tr {
      td:nth-child(1),
      td:nth-child(2),
      td:nth-child(3) {
        input {
          color: $color-royal-blue;
        }
      }
      td:last-child {
        width: 50px;
      }
    }
  }
}

.add-row-container {
  display: flex;
  padding: 20px 20px 10px 10px;
  button {
    &[disabled] {
      pointer-events: none;
      cursor: default;
      opacity: 0.6;
    }
  }
}

.text-button {
  color: $color-royal-blue;
  font-size: 14px;
  font-family: $font-name-merriweather-sans-light;
  letter-spacing: 0.5px;
  cursor: pointer;
  a {
    color: $color-royal-blue;
  }
  a:link {
    text-decoration: none;
  }
}

.no-flex-container {
  margin-right: 60px;
  margin-left: 60px;
  .input-card-container {
    max-width: 600px;
    margin-right: 0px;
    width: min-content;
  }
}

.import-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 8px;
  button {
    margin-left: 1em;
    &[disabled] {
      pointer-events: none;
      cursor: default;
      opacity: 0.6;
    }
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
        span {
          color: darken($color-blue-ribbon-1, 15%);
        }
        svg {
          path {
            fill: darken($color-blue-ribbon-1, 15%);
          }
        }
      }
    }
  }
  .icon-general {
    height: 22px;
    width: 22px;
    margin-right: 5px;
  }
}
