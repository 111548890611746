@import "../../sma-shared/Styles/index.scss";
@import "../../sma-shared/Styles/helpers/sma_fonts.scss";

.notification-area {
  display: flex;
  flex-direction: column;
  background-color: rgba($color-notificaiton, 0.7);
  padding: 1.5em;
  color: $color-black-pearl;
  font-size: $font-size-body;
  border-radius: 5px;
  margin-bottom: 2em;
  .content-area {
    display: flex;
    align-items: flex-start;
    font-weight: 400;
    text-align: left;
    margin-bottom: 10px;
    font-family: $font-name-merriweather-sans-light;
    .icon-area {
      margin-right: 1.2em;
      svg {
        width: 18px;
        height: 21px; // IE11 FIX
      }
    }
  }
  .link-area {
    align-self: flex-end;
    display: flex;
    align-items: center;
    justify-content: center;
    a {
      display: flex;
      align-items: center;
      position: relative;
      transition: all 0.3s ease;
      font-size: 14px;
    }
    svg {
      position: relative;
      margin-left: 10px;
      width: 14px;
      height: 14px;
      transition: all 0.1s ease;
    }
  }
}
