@import "../../sma-shared/Styles/index.scss";
@import "../../sma-shared/Styles/helpers/sma_fonts.scss";

@import "../../sma-shared/Styles/helpers/sma_fonts_woff";
@import "../../sma-shared/Styles/helpers/sma_fonts_woff2";

@import "../../sma-shared/Styles/partials/app_base";
@import "../../sma-shared/Styles/partials/sma_buttons";
@import "../../sma-shared/Styles/partials/sma_container";
@import "../../sma-shared/Styles/partials/sma_content";

a {
  &:hover {
    text-decoration: none;
  }

  &:visited,
  :hover,
  :active {
    color: inherit;
  }
}

body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

#root {
  height: 100%;
  width: 100%;
  .App {
    min-width: 1440px;
    text-align: center;
    height: 100%;
    display: flex;
    flex-direction: column;
    user-select: none;
    .application-content {
      flex: 1 0 auto;
      background-color: #fff;
      margin-bottom: auto;
      font-family: $font-name-merriweather-sans;
    }
  }
}

.App {
  .icon-general {
    height: 12px;
    width: 12px;
    margin-right: 10px;
    display: inline-flex;

    svg {
      //width: 100%;
      height: 100%;
    }
  }
}

.two-container-page {
  width: 100%;
  height: 100%;
  margin: 0;
  display: flex;
  .container-left {
    width: 22.7%;
    min-width: 315px;
    padding: 60px 40px 20px 40px;
    background-color: $color-alice-blue;
    height: 100%;
    .sticky-left-container {
      position: fixed; /* for browsers that do not support sticky */
      position: sticky;
      top: 80px;
    }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      /* IE11 FIX ONLY */
      .sticky-left-container {
        position: fixed; /* for browsers that do not support sticky */
        top: 80px;
        width: 14%;
      }
    }
  }

  .container-right {
    margin: 48px 0px 60px 0px;
    padding-bottom: 25px;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    &.overview-content {
      margin: 48px 60px 0px 60px;
      .page-header {
        max-width: inherit;
      }
    }
    .create-gift-page-content {
      margin-left: 60px;
      margin-right: 60px;
      box-shadow: none;
      display: flex;
      width: 100%;
      flex-direction: column;
      align-items: center;
      .input-card-container {
        max-width: 600px;
        margin-right: 0px;
        width: -moz-available; // firefox-fix
        width: 100%; // IE11 FIX
      }
    }
    .flex-container {
      display: flex;
      justify-content: space-between;
      height: -webkit-fill-available;
      width: -webkit-fill-available;
      height: 100%;
      &.with-sidebar {
        height: auto;
        width: -moz-available; // firefox-fix
        width: 100%;
      }
    }
  }
}

.one-container-page {
  width: 100%;
  height: 100%;
  margin: 0;
  display: flex;

  .container-right {
    margin: 30px auto 50px auto;
    padding-bottom: 25px;
    width: 100%;
    max-width: 850px;
    display: flex;
    flex-direction: column;
  }
}

.text-input-container,
.file-input-container {
  margin-bottom: 20px;

  input[type="text"] {
    height: 52px;
    border-radius: 4px;
    padding: 14px 20px;
  }

  .text-error-message {
    color: $color-invalid;
    font-size: 12px;
    height: 100%;
    position: relative;
    padding-top: 8px;
    width: 100%;
    text-align: left;
  }

  input[type="text"],
  textarea {
    &::-webkit-input-placeholder {
      font-family: $font-name-merriweather-sans;
      color: $color-manatee;
      font-style: italic;
    }

    &::-moz-placeholder {
      font-family: $font-name-merriweather-sans;
      color: $color-manatee;
      font-style: italic;
    }

    &:-ms-input-placeholder {
      font-family: $font-name-merriweather-sans;
      color: $color-manatee;
      font-style: italic;
    }

    &::-ms-input-placeholder {
      font-family: $font-name-merriweather-sans;
      color: $color-manatee;
      font-style: italic;
    }

    &::placeholder {
      font-family: $font-name-merriweather-sans;
      color: $color-manatee;
      font-style: italic;
    }
  }

  .form-control {
    &:focus {
      border-color: $color-royal-blue;
      box-shadow: none;
      outline: 0 none;
    }
  }

  label {
    width: 100%; // IE11 FIX
    color: $color-tuna;
    font-family: $font-name-merriweather-sans-light;
    font-size: 14px;
  }

  .optional-tag {
    margin-left: 6px;
    font-style: italic;
    opacity: 0.7;
  }
}

/* Intro JS style config */
.introjs-tooltip {
  font-family: $font-name-merriweather-sans;
  font-size: 12px;
  min-width: 250px;
  padding: 25px 20px 10px 20px;
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.1);
  border-radius: 8px;

  .title {
    font-size: 14px;
    padding-bottom: 10px;
    display: block;
  }

  .introjs-tooltipbuttons {
    margin-top: 10px;
    font-family: $font-name-merriweather-sans;
  }

  .introjs-button {
    border: none;
    background: none;
    font-size: 12px;
    color: $color-blue-ribbon-1;
    padding: 0;
  }

  .introjs-button:hover {
    box-shadow: none;
  }

  .introjs-nextbutton .introjs-donebutton {
    display: inline !important;
    /* keep done button */
    color: inherit;
  }

  .introjs-nextbutton {
    padding: 10px 25px 10px 0;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50px' height='50px' viewBox='0 0 65 65'%3E%3Cdefs /%3E%3Cg id='digitree_ui' stroke='none' strokeWidth='1' fill='%23ffffff' fillRule='evenodd'%3E%3Cg id='button_1-radiobutton-default' transform='translate(-1460.000000, -377.000000)'%3E%3Cg id='icon_next:default' transform='translate(1461.000000, 378.000000)'%3E%3Ccircle stroke='%23075bfa' cx='30' cy='30' r='30' /%3E%3Cpath d='M40.1607143,30.1405967 L26.9107143,42.4700007 C26.6845227,42.6804767 26.4166682,42.7857132 26.1071429,42.7857132 C25.7976175,42.7857132 25.529763,42.6804767 25.3035714,42.4700007 L22.3392857,39.7116704 C22.1130941,39.5011944 22,39.2519502 22,38.9639303 C22,38.6759104 22.1130941,38.4266662 22.3392857,38.2161902 L31.8214286,29.3928566 L22.3392857,20.569523 C22.1130941,20.359047 22,20.1098028 22,19.8217829 C22,19.533763 22.1130941,19.2845188 22.3392857,19.0740428 L25.3035714,16.3157125 C25.529763,16.1052364 25.7976175,16 26.1071429,16 C26.4166682,16 26.6845227,16.1052364 26.9107143,16.3157125 L40.1607143,28.6451165 C40.3869059,28.8555925 40.5,29.1048367 40.5,29.3928566 C40.5,29.6808765 40.3869059,29.9301207 40.1607143,30.1405967 Z' id='Combined-Shape' fill='%23075bfa' /%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: center right;
    background-size: 20px;
  }

  .introjs-prevbutton {
    float: left;
    color: #000000;
    padding: 10px 0 10px 25px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50px' height='50px' viewBox='0 0 50 50'%3E%3Cg id='digitree_ui'%3E%3Cg id='button_1-radiobutton-default' transform='translate(-1460.000000, -377.000000)'%3E%3Cg id='icon_next:default' transform='translate(1461.000000, 378.000000)'%3E%3Ccircle fill='%23FFFFFF' stroke='%23000000' cx='22.847' cy='22.846' r='23.077' /%3E%3Cpath id='Combined-Shape' fill='%23000000' d='M14.77,22.379c0-0.221,0.088-0.413,0.262-0.575l10.192-9.484 c0.174-0.162,0.38-0.243,0.618-0.243s0.443,0.081,0.618,0.243l2.28,2.122c0.174,0.162,0.261,0.354,0.261,0.575 c0,0.222-0.087,0.414-0.261,0.575l-7.294,6.787l7.294,6.787c0.174,0.162,0.261,0.354,0.261,0.576 c0,0.221-0.087,0.412-0.261,0.574l-2.28,2.122c-0.175,0.161-0.38,0.243-0.618,0.243s-0.444-0.082-0.618-0.243l-10.192-9.484 C14.857,22.792,14.77,22.601,14.77,22.379z' /%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: center left;
    background-size: 20px;
  }

  .introjs-nextbutton.introjs-disabled {
    display: none;
  }

  .introjs-skipbutton {
    content: "";
    background-image: url("../../Assets/svg/close-icon-grey.svg");
    position: absolute;
    display: block;
    top: 10px;
    right: 15px;
    height: 15px;
    width: 15px;
    margin-top: 5px;
  }
  .introjs-skipbutton.introjs-donebutton:before {
    content: "";
    background-image: url("../../Assets/svg/close-icon-grey.svg");
    position: absolute;
    display: block;
    top: 10px;
    right: 15px;
    height: 15px;
    width: 15px;
    margin-top: 5px;
  }

  .introjs-donebutton {
    display: inline-block;
    color: $color-blue-ribbon-1;
    content: "";
    background: none;
    width: inherit;
    position: initial;
    top: 0;
    right: 0;
    padding: 10px 0 10px 0;
  }
}

.page-links-container {
  justify-content: center !important;
  .header__page-link {
    &.alert-circle {
      position: relative;
      &:after {
        content: "";
        width: 10px;
        height: 10px;
        display: block;
        position: absolute;
        top: 17.5px;
        right: 8px;
        background: $color-blue-ribbon-2;
        border-radius: 50%;
      }
    }
  }
}

.optional-info {
  color: #8f9299;
  font-style: italic;
  padding-left: 5px;
}

.right {
  float: right;
}

.line-separator {
  height: 1px;
  margin: 20px 0px;
  background-color: $color-hawkes-blue;
}
.grey-container-without-sidebar {
  background-color: $color-alice-blue;
  width: 505px;
  min-width: 505px;
  margin-top: -48px;
  margin-bottom: -30px;
}
