@import "../../../sma-shared/Styles/index.scss";

.basic-data-container {
  display: flex;
  width: 100%;
  height: 100%;
  align-content: flex-start;
  background-color: $color-white;
  .page-header {
    .load-job-profile {
      font-size: 14px;
      color: $color-royal-blue;
      padding: 0;
      display: flex;
      align-items: center;
    }
  }
  .inputs-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
}
