@import "../../../sma-shared/Styles/index.scss";
@import "../../../sma-shared/Styles/helpers/sma_fonts.scss";

table.employees-overview {
  thead {
    tr {
      th:last-child {
        width: 50px;
      }
      th:nth-child(1) {
        white-space: nowrap;
        width: 10%;
      }
      th:nth-child(2),
      th:nth-child(3),
      th:nth-child(6) {
        white-space: nowrap;
        width: 10%;
      }
    }
  }

  tbody {
    tr {
      td:nth-child(1) {
        white-space: nowrap;
        width: 10%;
      }
      td:nth-child(2),
      td:nth-child(3) {
        color: $color-royal-blue;
        white-space: nowrap;
        width: 10%;
      }
      td:nth-child(6) {
        width: 10%;
      }
      td:last-child {
        width: 50px;
      }
    }
    .inactive {
      color: gray;
    }
  }
}
