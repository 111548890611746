@import "../../sma-shared/Styles/index.scss";
@import "../../sma-shared/Styles/helpers/sma_fonts.scss";

.help-text-button {
  position: fixed;
  top: 140px;
  right: -27px;
  transform: rotate(270deg);
  z-index: 2;
  font-family: "HaufeMerriweatherSans";
  font-size: 14px;
  letter-spacing: 0.69px;
  background-color: $color-royal-blue;
  color: #fff;
  padding: 9px 15px;
  border-radius: 6px 6px 0 0;
  display: flex;
  .icon-general {
    cursor: pointer;
    display: inline;
    width: 18px !important;
    height: 18px !important;
    margin-left: 9px;
    margin-right: 0px !important;
    transform: rotate(90deg);
    align-self: center;
    svg {
      height: 18px !important;
      width: 18px !important;
      margin-left: -1px;
    }
  }
}

.help-text-container {
  top: 0; // IE11 FIX
  height: 100%; // IE11 FIX
  position: fixed;
  right: 0px;
  padding-top: 70px;
  width: 505px;
  background-color: $color-hawkes-blue;
  display: flex;
  flex-direction: column;
  .help-text-close-button {
    text-align: right;
    height: 25px;
    margin-right: 20px;
    margin-left: auto;
    margin-top: 10px;
    width: 100%;
    padding-right: 20px;
    .icon-general {
      cursor: pointer;
      display: inline;
      width: 20px !important;
      height: 20px !important;
      margin-left: 5px;
      margin-right: 0px;
      svg {
        height: 12px !important;
        width: 12px !important;
      }
    }
  }
  .help-text-left-button {
    position: absolute;
    top: 140px;
    right: 472px;
    min-width: 97px; // IE11 FIX
    transform: rotate(270deg);
    z-index: 2;
    font-family: "HaufeMerriweatherSans";
    font-size: 14px;
    letter-spacing: 0.69px;
    background-color: $color-hawkes-blue;
    color: $color-black-pearl;
    padding-top: 9px;
    padding-bottom: 9px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 6px 6px 0 0;
    display: flex;
    .icon-general {
      cursor: pointer;
      display: inline;
      width: 18px !important;
      height: 18px !important;
      margin-left: 9px;
      margin-right: 0px;
      transform: rotate(90deg);
      svg {
        height: 18px !important;
        width: 18px !important;
        margin-left: 1px;
      }
    }
  }
  .helptext-scroll-area {
    overflow: auto;
    overflow-x: hidden;
  }
  .help-text-content {
    overflow: auto;
    color: $color-black-pearl;
    font-family: HaufeMerriweatherSansLt;
    letter-spacing: 0;
    text-align: left;
    padding: 15px 40px 20px 40px;
    p .line-separator {
      background-color: $color-gray;
    }
    .help-text-title {
      font-size: 20px;
      padding-bottom: 20px;
    }
    .help-text-sections {
      display: flex;
      flex-direction: column;
      cursor: text;
      user-select: text;
      .help-text-section-title {
        color: $color-royal-blue;
        font-family: HaufeMerriweatherSans;
        font-size: 12px;
        letter-spacing: 0.69px;
        line-height: 24px;
      }
      .help-text-section {
        font-family: HaufeMerriweatherSans;
        font-weight: bold;
        line-height: 40px;
        text-transform: uppercase;
        margin-bottom: 7px;
        font-size: 14px;
      }
      .help-text-description {
        margin-bottom: 25px;
        line-height: 22px;
        font-size: 13px;
      }
    }
  }
}
