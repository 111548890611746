@import "../../sma-shared/Styles/index.scss";

.file-input-container {
  .custom-file-input-container {
    width: 100%;
    height: 150px;
    border: 1px dashed #c2c6cc;
    border-radius: 6px;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    &.onDrop {
      border-color: blue;
    }
    &.onDropLeave {
      border-color: #c2c6cc;
    }
    .file-upload-hint {
      font-family: $font-name-merriweather-sans-light;
      color: $color-manatee;
      font-size: 14px;
      font-style: italic;
      margin-bottom: 13px;
    }
    .custom-file-input {
      overflow: hidden;
      position: relative;
      background-color: $color-hawkes-blue;
      border-radius: 0.5em;
      height: 36px;
      width: 113px;
      text-align: center;
      opacity: 1;
      font-weight: 700;
      padding: 10px 20px;
      display: flex;
      cursor: pointer;
      align-self: center;
      [type="file"] {
        display: block;
        position: absolute;
        top: 0;
        right: 115px; /* Chrome cursor pointer fix - move input choose file btn to the left */
        opacity: 0;
        text-align: right;
        filter: alpha(opacity=0);
        min-width: 100%;
        padding: 0;
        min-height: 36px;
      }
      &[disabled] {
        cursor: not-allowed;
        opacity: 0.3;
      }
    }
  }
}
