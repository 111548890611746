@import "../../sma-shared/Styles/index.scss";

.modal-info {
  background-color: $color-white;
  position: relative;
  margin: auto;
  -moz-user-select: none;
}

.info-box-container {
  background-color: $color-white;
  color: $color-oxford-blue;
  font-family: $font-family-merriweather-sans-light;
  font-size: $font-size-info-box-container;
  height: 100%;
  line-height: $line-height-info-box-container;
  width: 580px;
  z-index: 1;
}

.close-icon-container {
  cursor: pointer;
  float: right;
  margin-top: $base-spacing-5x;
}

.gradient-line {
  height: 3px;
  width: 580px;
  box-shadow: none;
}

.info-text-container {
  margin-top: $base-spacing-10x;
  margin-bottom: $base-spacing-7x;
  margin-left: $base-spacing-5x;
  margin-right: $base-spacing-5x;
  a,
  a:visited,
  a:active {
    color: $color-blue-ribbon-2;
    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
}

.info-header-container {
  font-weight: $font-weight-bold;
  font-size: $font-size-info-header-container;
  margin-bottom: $base-spacing-4x;
}

.accept-btn-container {
  align-items: flex-end;
  display: flex;
  margin-left: auto;
  margin-top: $base-spacing-10x;
  width: 50%;
}
