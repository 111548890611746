@import "../../../sma-shared/Styles/helpers/sma_fonts.scss";
@import "../../../sma-shared/Styles/index.scss";
.workflow-component {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 20px;
  font-family: $font-name-merriweather-sans-light;
  font-size: 14px;
  line-height: 24px;
  padding-left: 2.5em;

  .notAllow {
    pointer-events: none;
    opacity: 0.5;
  }

  a {
    color: $color-black-pearl;
    text-decoration: none;
  }

  .active {
    color: $color-royal-blue;
  }

  .done {
    color: $color-black-pearl;
  }

  .nav-icon {
    height: 11px;
    vertical-align: inherit;
    position: absolute;
    left: -28.5px;
    top: 7px;
  }

  a:last-child {
    svg {
      height: 12px;
      width: 13px;
      top: 6.5px;
      left: -29.5px;
    }
    &.current {
      &:before {
        display: none; //do not show bullet on last step
      }
      svg {
        path {
          fill: $color-royal-blue; //change summary icon color when active
        }
      }
    }
  }
  a {
    position: relative;
    padding-bottom: 15px;
    &:first-child {
      &:after {
        display: none;
      }
    }
    &:before {
      content: "";
      position: absolute;
      height: 25px;
      width: 25px;
      border: 1px solid $color-black-pearl;
      border-radius: 50%;
      left: -35.5px;
    }
    &:after {
      content: "";
      background-color: $color-black-pearl;
      position: absolute;
      width: 1px;
      height: 14px;
      top: -14px;
      left: -23.5px;
    }

    &.current {
      &:before {
        content: "";
        display: inline-block;
        position: absolute;
        border-color: $color-royal-blue;
        height: 11px;
        width: 11px;
        background-color: $color-royal-blue;
        border-radius: 50%;
        left: -28.5px;
        top: 7px;
      }
      &:after {
        background-color: $color-royal-blue;
      }
      .circle {
        border: 1px solid $color-royal-blue;
        border-radius: 50%;
        position: absolute;
        height: 25px;
        width: 25px;
        left: -35.5px;
        top: 0px;
      }
    }

    svg {
      width: 11px;
      height: 8px;
      position: absolute;
      left: -28.5px;
      top: 9px;
      g {
        g {
          fill: $color-black-pearl;
        }
      }
    }
  }
  #step-fibu {
    margin-left: 35px;
    &:after {
      display: none;
    }
  }
  #step-payroll {
    text-align: left;
    margin-left: 35px;
    max-width: 140px;
  }
  #step-payroll + #step-report {
    &:after {
      height: 116px;
      top: -116px;
    }
  }
}
