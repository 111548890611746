@import "../../Styles/index.scss";

.footer {
  span {
    color: $color-white;
  }
  align-items: center;
  background-color: $color-regal-blue-2;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.2);
  display: flex;
  font-family: $font-family-merriweather-sans;
  font-size: $font-size-footer;
  min-height: 80px;
  padding-left: $base-spacing-9x;
  padding-right: $base-spacing-10x;
  position: relative;
  bottom: 0;
  z-index: 3;
}
