.collapsible-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 19px;
  .collapsible-title {
    margin-bottom: 0px;
    font-family: "HaufeMerriweatherSans";
    margin-bottom: 0px;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 14px;
  }
}

.collapsible-icon {
  background: none;
  display: flex;
  height: 10px;
  width: 10px;
  //margin-bottom: 6px;
}

.collapsible-children {
  text-align: left;
  color: #45484d;
  font-family: "HaufeMerriweatherSansLt";
  font-size: 14px;
  margin-bottom: 20px;
  width: 445px;
  border-bottom: solid #c2c6cc 1px;
}

.children-content {
  margin-bottom: 20px;
}

.collapsible-btn {
  box-shadow: none !important;
  padding: 0 !important;
}
