@import "../../sma-shared/Styles/index.scss";

.active-filters-container {
  display: flex;
  margin-right: 20px;
  align-items: center;
  .active-filter {
    width: fit-content;
    display: flex;
    align-items: center;
    color: $color-black-pearl;
    margin-left: 20px;
    margin-right: 10px;
    font-size: 14px;
    font-family: HaufeMerriweatherSansLt;

    button {
      font-family: "HaufeMerriweatherSans";
      padding: 0 0 0 5px;
      color: $color-royal-blue;
      margin-left: 10px;
    }

    .icon-general svg {
      height: 9px;
      width: 9px;
    }
  }
  .active-filter-title {
    opacity: 0.7;
  }
}
