@import "../../sma-shared/Styles/index.scss";
@media screen and (max-width: 1550px) {
  .auth-container .left-container .form .sections-container {
    flex-direction: column;
  }
}
.auth-container {
  background-color: #f5f7fa;
  display: flex;
  height: 100%;
  width: 100%;
  .divider-line {
    height: 1px;
    width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
    width: -moz-available; /* WebKit-based browsers will ignore this. */
    width: strech;
    margin-bottom: 20px;
    margin-left: -40px;
    margin-right: -40px;
    background-color: $color-hawkes-blue;
  }
  .left-container {
    display: flex;
    flex: 1;
    width: 920px;
    flex-direction: column;
    padding-top: 40px;
    padding-left: 80px;
    .text-button {
      text-align: left;
      font-family: HaufeMerriweatherSans;
      font-size: 14px;
      letter-spacing: 0;
    }
    .btn-transparent {
      text-align: left;
      font-family: HaufeMerriweatherSans;
      font-size: 14px;
      letter-spacing: 0;
      border-radius: 6px;
      border: 1px solid $color-royal-blue;
      width: fit-content;
      padding: 10px 20px;
      color: $color-royal-blue;
      transition: all 0.2s ease;
      &:hover {
        background-color: lighten($color-royal-blue, 45%);
      }
    }
    .form {
      padding-left: 110px;
      padding-right: 110px;
      text-align: left;
      .page-title {
        color: $color-black-pearl;
        font-family: HaufeMerriweatherSansLt;
        font-size: 40px;
        text-align: left;
        margin-top: 34px;
        margin-bottom: 19px;
      }

      .description {
        word-wrap: break-word;
        text-align: justify;
        color: $color-black-pearl;
        font-family: HaufeMerriweatherSansLt;
        font-size: 12px;
        line-height: 22px;
        padding-bottom: 35px;
      }
      .message {
        text-align: left;
        padding-bottom: 10px;
        margin-bottom: 20px;
      }
      .error {
        color: $color-red;
      }
      .success {
        color: $color-green-blue;
      }
      .line-separator {
        background: transparent;
        text-align: center;
        height: auto;
        padding: 2.5em 0;
        margin: 0;
        span {
          font-size: 12px;
          font-family: $font-family-merriweather-sans-light;
          display: flex;
          align-items: center;
          justify-content: center;
          color: $color-black-pearl;

          &::before,
          &::after {
            content: "";
            display: block;
            width: 150%;
            height: 1px;
            background-color: $color-gray;
            padding: 0 1em;
            min-width: 220px;
          }
          &::before {
            margin-right: 1em;
            margin-left: -10%;
          }
          &::after {
            margin-left: 1em;
            margin-right: -10%;
          }
        }
      }
      label {
        font-size: 12px;
        margin: 0;
      }
      .label-container {
        margin-bottom: 13px;
      }
      .login-box-buttons {
        .forgot-password-actions {
          margin-bottom: 20px;
        }

        .login-actions {
          display: flex;
          align-items: center;
          .right {
            margin-left: auto;
            font-size: 14px;
            line-height: 1;
            padding: 10px 20px;
          }
        }
      }
      .sections-container {
        display: inline-flex;
      }
      .btn-primary {
        width: fit-content;
        padding-left: 20px;
        padding-right: 20px;
      }
      .btn-back {
        background-color: $color-hawkes-blue;
        width: fit-content;
        padding-left: 20px;
        padding-right: 20px;
      }
      .input-card-container {
        z-index: 1;
        height: fit-content;
        width: 445px;
        overflow-x: hidden;
        padding: 2.5em;
      }
      .signup-section {
        display: flex;
        flex-direction: column;
        .input-card-container {
          width: 350px;
        }
        .icon-general {
          margin-left: 10px;
        }
      }
    }
    .checkbox-container {
      display: flex;
      align-items: flex-start;
      label {
        margin-bottom: auto;
        margin-top: 0;
        font-size: 13px;
        font-family: $font-family-merriweather-sans-light;
        hyphens: auto;
        &:first-of-type {
          margin-right: 12px;
          margin-top: 3px;
          max-width: 15px;
          width: 100%;
          height: 15px;
          padding: 0;
          &:after {
            font-size: 12px !important;
            left: 1.5px;
            top: -3px;
          }
        }
      }
      &.invalid {
        label {
          &:first-of-type {
            border-width: 1px;
          }
        }
      }
    }
  }

  .right-container {
    width: 30%;
    background-image: url("../../Assets/png/gift.jpg");
    background-size: cover;
  }
}
