@import "../../sma-shared/Styles/index.scss";
@import "../../sma-shared/Styles/helpers/sma_fonts";

.notFoundMessage {
  margin: 200px auto;
}

.navigation-page {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.container-left {
  .line-separator {
    background-color: $color-gray;
    margin: 34px 0px;
  }
}

.container-right {
  .page-header {
    display: flex;
    margin-bottom: 21px;
    font-family: $font-family-merriweather-sans-light;
    font-size: 20px;
    max-width: 600px;
    width: 100%;
    align-self: center;
    flex-direction: column;
    align-items: flex-start;
    max-height: 100%;
    margin-bottom: 1.5em;

    .title {
      font-size: 20px;
      color: $color-black-pearl;
      margin-bottom: 14px;
    }
    .subtitle {
      font-size: 14px;
      color: $color-black-pearl;
    }
  }

  $color-green-approved: #1fb218;
  $color-orange-pending: #ffae03;
  $color-red-rejected: #e63120;
  .comment-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 600px;
    width: 100%;
    background-color: $color-white;
    padding: 2em;
    box-shadow: 0 0 15px 1px rgba($color-black, 0.1);
    margin-bottom: 2em;
    // max-width: 445px;
    border-radius: 4px;
    .title {
      margin-bottom: 19px;
      font-weight: 700;
      text-transform: uppercase;
      font-size: 14px;
    }
    .comment {
      text-align: left;
      color: $color-tuna;
      font-family: "HaufeMerriweatherSansLt";
      font-size: 14px;
      margin-bottom: 20px;
      width: 445px;
    }
  }
  .line-separator {
    width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
    width: -moz-available; /* WebKit-based browsers will ignore this. */
    width: strech;
    margin: 10px -120px 40px -60px;
  }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    /* IE11 FIX ONLY */
    .line-separator {
      width: 100%; /*FIX FOR IE11*/
    }
  }

  .approval-buttons-area {
    margin: 0px -40px -40px -30px;
    padding: 20px 40px 20px 20px;
    border-top: 1px solid $color-hawkes-blue;
    button {
      &.btn-transparent {
        color: $color-blue-ribbon-1;
        outline: 0;
        float: left;
        &[disabled] {
          pointer-events: none;
          background-color: none;
          cursor: default;
          opacity: 0.6;
        }
      }
      &.btn-primary {
        float: right;
      }
    }
  }
  .status-label-area {
    .approval-status {
      border-radius: 0 27.5px 27.5px 0;
      color: #ffffff;
      font-family: HaufeMerriweatherSans;
      font-size: 19px;
      font-weight: bold;
      height: 55px;
      letter-spacing: 0;
      line-height: 25px;
      padding: 15px 40px 15px 40px;
      text-transform: uppercase;
      width: fit-content;
      display: table; // IE11 FIX
      margin-left: -30px;
      background-color: $color-orange-pending;
      margin-bottom: 24px;
    }

    .approvalRequest {
      background-color: $color-orange-pending;
    }
    .approved,
    .financialAccountingProcessing,
    .payrollProcessing,
    .completed {
      background-color: $color-green-approved;
    }
    .rejected {
      background-color: $color-red-rejected;
    }
  }

  .guidance-title {
    color: $color-black-pearl;
    font-family: HaufeMerriweatherSansLt;
    font-size: 20px;
    letter-spacing: 0;
    line-height: 25px;
    text-align: left;
    max-width: 600px;
    width: 100%;
  }
  .guidance-info {
    color: $color-black-pearl;
    font-family: "HaufeMerriweatherSansLt";
    font-size: 12px;
    margin-top: 10px;
    margin-bottom: 20px;
    text-align: left;
    width: 100%;
    max-width: 600px;
  }

  .info-container {
    background-color: rgba($color-notificaiton, 0.7);
    margin: 15px 0;
    padding: 30px;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    line-height: 24px;
    min-width: 445px;
    width: 100%;
    max-width: 600px;
    &.last {
      margin-bottom: 30px;
    }
    .title {
      margin-bottom: 19px;
      font-weight: 700;
      text-transform: uppercase;
      font-size: 14px;
    }

    .info {
      font-family: HaufeMerriweatherSansLt;
      font-size: 12px;
      letter-spacing: 0;
      padding-left: 10px;
      text-align: left;
    }

    .info-accounts {
      font-family: HaufeMerriweatherSansLt;
      font-size: 12px;
      line-height: 18px;
      padding-left: 10px;
      width: -webkit-fill-available;
      width: 100%; /* mozilla and ie11 fix*/

      .info-header {
        font-family: HaufeMerriweatherSans;
        font-size: 14px;
        font-weight: bold;
        line-height: 24px;
      }

      .line-separator {
        opacity: 0.2;
        background-color: $color-black-pearl;
        margin: 8px 0px 10px 0px;
      }

      div {
        text-align: left;
        margin-bottom: 1em;
        display: flex;
        span {
          width: 80px;
          padding-right: 10px;
        }
        span:first-child {
          min-width: 220px;
          flex-grow: 2;
        }
      }
    }
  }

  .grey-container-with-sidebar {
    background-color: $color-alice-blue;
    width: 95px; // 505-410 (grey-container-falldaten)
    min-width: 95px;
    margin-top: -48px;
    margin-bottom: -30px;
  }
}
